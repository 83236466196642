import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { ProgramSchema, UpdateProgramSchemaStaff } from "../../api-client";
import {
  useProgram,
  useUpdateProgramStaffMutation,
} from "../../crud/pricing/hooks";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

type Props = {
  programId: number;
};

const LimitFormInner = ({ program }: { program: ProgramSchema }) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm<UpdateProgramSchemaStaff>({
    mode: "onBlur",
    defaultValues: {
      coreLimit: program.coreLimit,
      coreLimitMembers: program.coreLimitMembers,
      gpusLimit: program.gpusLimit,
      membersLimit: program.membersLimit,
      nodeLimit: program.nodeLimit,
    },
  });

  const { mutate, isLoading } = useUpdateProgramStaffMutation();

  return (
    <Card>
      <CardHeader
        title={`Update Workspace Limits for Organization: ${program.organizationName}`}
      />
      <CardContent>
        <form
          onSubmit={handleSubmit((data) => {
            mutate(
              { programId: program.id, updateProgramSchemaStaff: data },
              {
                onError: async (error) => {
                  const errorText = await error.response.text();
                  enqueueSnackbar(errorText, { variant: "error" });
                },
                onSuccess: (result) => {
                  enqueueSnackbar("Limits updated", { variant: "success" });
                  reset({
                    coreLimit: result.coreLimit,
                    coreLimitMembers: result.coreLimitMembers,
                    gpusLimit: result.gpusLimit,
                    membersLimit: result.membersLimit,
                    nodeLimit: result.nodeLimit,
                  });
                },
              },
            );
          })}
        >
          <Stack spacing={2}>
            <TextField
              {...register("coreLimit", { required: "Core limit is required" })}
              variant="outlined"
              label="Core Limit"
              error={Boolean(errors.coreLimit)}
              helperText={errors.coreLimit ? errors.coreLimit.message : ""}
              type="number"
            />
            <TextField
              {...register("nodeLimit", { required: "Node limit is required" })}
              variant="outlined"
              label="Node Limit"
              error={Boolean(errors.nodeLimit)}
              helperText={errors.nodeLimit ? errors.nodeLimit.message : ""}
              type="number"
            />
            <TextField
              {...register("membersLimit", {
                required: "Members limit is required",
              })}
              variant="outlined"
              label="Members Limit"
              error={Boolean(errors.membersLimit)}
              helperText={
                errors.membersLimit ? errors.membersLimit.message : ""
              }
              type="number"
            />
            <TextField
              {...register("coreLimitMembers", {
                required: "Core limit members is required",
              })}
              variant="outlined"
              label="Core Limit Members"
              error={Boolean(errors.coreLimitMembers)}
              helperText={
                errors.coreLimitMembers ? errors.coreLimitMembers.message : ""
              }
              type="number"
            />
            <TextField
              {...register("gpusLimit", {
                required: "GPU limit is required",
              })}
              variant="outlined"
              label="GPU Limit"
              error={Boolean(errors.gpusLimit)}
              helperText={errors.gpusLimit ? errors.gpusLimit.message : ""}
              type="number"
            />
            <LoadingButton
              type="submit"
              loading={isLoading}
              disabled={!isDirty}
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};
export const ProgramEditForm = ({ programId }: Props): React.ReactElement => {
  const { data, isSuccess } = useProgram(programId);
  if (isSuccess) {
    return <LimitFormInner program={data} />;
  }

  return <Typography>Loading...</Typography>;
};
