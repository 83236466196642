import { UseQueryResult, useQuery } from "react-query";
import { Tag, TagValues } from "./types";
import { ApiClient } from "../../apiUtils";
import {
  DeclarativeViewsTagsGetAccountTagValuesEndpointRequest,
  DeclarativeViewsTagsGetTagListRequest,
} from "../../api-client";

export const useTags = (
  query: DeclarativeViewsTagsGetTagListRequest,
): UseQueryResult<Tag[]> => {
  const result = useQuery(["tags", query], () =>
    ApiClient.declarativeViewsTagsGetTagList(query),
  );
  return result;
};

export const useAllTagValues = (
  query: DeclarativeViewsTagsGetAccountTagValuesEndpointRequest,
): UseQueryResult<TagValues> => {
  const result = useQuery<TagValues>(["allTagValues", query], () =>
    ApiClient.declarativeViewsTagsGetAccountTagValuesEndpoint(query),
  );
  return result;
};
