/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const BillingEventKind = {
    ActivateAccount: 'activate_account',
    DeactivateAccount: 'deactivate_account',
    Instance: 'instance',
    LimitsAdjustment: 'limits_adjustment',
    ManualAdjustment: 'manual_adjustment',
    MonthlyGrant: 'monthly_grant',
    PaygPayment: 'payg_payment',
    ProgramChange: 'program_change'
} as const;
export type BillingEventKind = typeof BillingEventKind[keyof typeof BillingEventKind];


export function BillingEventKindFromJSON(json: any): BillingEventKind {
    return BillingEventKindFromJSONTyped(json, false);
}

export function BillingEventKindFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEventKind {
    return json as BillingEventKind;
}

export function BillingEventKindToJSON(value?: BillingEventKind | null): any {
    return value as any;
}

