import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AuthenticatedContext } from "../crud/auth/context";
import { WorkspaceContext } from "./context";
import Cookies, { CookieAttributes } from "js-cookie";
import { JsonParam, QueryParamConfig, useQueryParam } from "use-query-params";
import { useLocalStorage } from "usehooks-ts";
import { isEqual } from "lodash";
import { Location, useLocation } from "react-router-dom";

const isSafari = navigator.userAgent.includes("Safari");

const defaultCookieOptions: CookieAttributes = {
  // No matter what I do, if I set "secure" on Safari
  // the cookie isn't written. It _shouldn't_ be a big
  // deal since Lax or Strict will still protect against
  // CSRF.
  secure: import.meta.env.FE_COOKIE_SECURE === "true" && !isSafari,
  sameSite: "Strict",
};

export const useWorkspaceContextSlug = (): string => {
  const { slug } = useContext(WorkspaceContext);
  return slug;
};

export const useToken = (): string => {
  const { token } = useContext(AuthenticatedContext);
  return token;
};

export const useCookie = (
  key: string,
  defaultValue?: string,
  options?: CookieAttributes,
): [string | undefined, (val: string | undefined) => void] => {
  const [stateCookie, setStateCookie] = useState<string | undefined>(() =>
    Cookies.get(key) ? Cookies.get(key) : defaultValue,
  );
  const setCookie = useCallback(
    (val: string | undefined) => {
      setStateCookie(val);
      if (val === undefined) {
        Cookies.remove(key, options ? options : defaultCookieOptions);
      } else {
        Cookies.set(key, val, options ? options : defaultCookieOptions);
      }
    },
    [key, options],
  );
  return [stateCookie, setCookie];
};

// Hook from https://usehooks.com/useDebounce/
export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
};

export const useInterval = (
  callback: () => void,
  delay: number | null,
): void => {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

type JSONSerializablePrimitive = string | number | boolean | null | undefined;
interface JSONSerializableObject {}
interface JSONSerializableArray extends Array<JSONSerializable> {}
type JSONSerializableCustomObject = Record<any, unknown>;
type JSONSerializable =
  | JSONSerializablePrimitive
  | JSONSerializableArray
  | JSONSerializableObject
  | JSONSerializableCustomObject;

interface UsePersistentJsonOptions<T extends JSONSerializable> {
  key: string;
  defaultValue?: T;
  persistDefault?: boolean;
}

interface UsePersistentJsonOptionsWithQueryParams<T extends JSONSerializable>
  extends UsePersistentJsonOptions<T> {
  // avoid typing these because they don't have to persist `T`, just enough of `T` to match options
  // which means `type` + either `name` or `id`
  queryParam: any;
  setQueryParam: any;
}

type usePersistentJsonOverload = {
  <T extends JSONSerializable>(
    options: UsePersistentJsonOptionsWithQueryParams<T>,
  ): [T, (val: T | undefined, allowHistory?: boolean) => void];
  <T extends JSONSerializable>(
    options: UsePersistentJsonOptionsWithQueryParams<undefined>,
  ): [T | undefined, (val?: T | undefined, allowHistory?: boolean) => void];
};

const useLocationEffect = (callback: (location: Location) => void): void => {
  const location = useLocation();
  useEffect(() => {
    callback(location);
  }, [location, callback]);
};

// variable used to enforce only loading a key from the query parameters ONCE
const LoadedQueryParam: { [key: string]: boolean } = {};

export const usePersistentJson: usePersistentJsonOverload = <
  T extends JSONSerializable,
>({
  key,
  defaultValue,
  queryParam,
  setQueryParam,
  persistDefault = false,
}: UsePersistentJsonOptionsWithQueryParams<T>): [
  T | undefined,
  (val: T | undefined) => void,
] => {
  // Persist a json blob in local storage,
  // and as a query parameter in the URL.

  // Use a ref to keep track of the actual desired query param value
  const queryParamRef = useRef(queryParam);
  // Local storage hook
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<
    T | undefined
  >(key, undefined);
  // State hook
  const [state, setState] = useState<T | undefined>(
    queryParam || localStorageValue || defaultValue,
  );
  const setFunction = useCallback(
    (val: T | undefined) => {
      if (isEqual(val, defaultValue) && !persistDefault) {
        // if this is default value (and we don't want to persist default), then clear what's in url and storage
        if (queryParam !== undefined) {
          setQueryParam(undefined, "replaceIn");
          queryParamRef.current = undefined;
        }
        if (localStorageValue !== undefined) {
          setLocalStorageValue(undefined);
        }
      } else {
        // otherwise, update url and storage if new value doesn't already match what's there
        if (!isEqual(queryParam, val)) {
          setQueryParam(val, "replaceIn");
        }
        if (!isEqual(localStorageValue, val)) {
          setLocalStorageValue(val);
        }
        if (!isEqual(queryParamRef.current, val)) {
          queryParamRef.current = val;
        }
      }
      setState(val);
    },
    [
      localStorageValue,
      queryParam,
      setLocalStorageValue,
      setQueryParam,
      defaultValue,
      persistDefault,
    ],
  );

  // This is what adds back the query param if you remove it or
  // navigate to a different URL that doesn't include query param.
  const keepQueryParamCorrect = useCallback(() => {
    if (LoadedQueryParam[key] === undefined) {
      // When the component mounts,
      // we should load from the query param as a priority,
      // then local storage takes precedence,
      // then the default value.
      if (queryParam) {
        setFunction(queryParam);
      } else if (localStorageValue) {
        setFunction(localStorageValue);
      } else {
        setFunction(defaultValue);
      }
      LoadedQueryParam[key] = true;
    } else {
      // If the query param changes, and something
      // besides us changed it (because our ref is incorrect),
      // set it back to what we expect.
      if (!isEqual(queryParamRef.current, queryParam)) {
        setQueryParam(queryParamRef.current, "replaceIn");
      }
    }
  }, [
    defaultValue,
    localStorageValue,
    queryParam,
    queryParamRef,
    setFunction,
    setQueryParam,
    key,
  ]);
  useLocationEffect(keepQueryParamCorrect);

  return [state, setFunction];
};

export const usePersistentJsonWithJsonParam = <T extends JSONSerializable>(
  options: UsePersistentJsonOptions<T>,
): [T, (val: T | undefined) => void] => {
  const [queryParam, setQueryParam] = useQueryParam(options.key, JsonParam);
  return usePersistentJson({ ...options, queryParam, setQueryParam });
};

type PersistentQueryParamOptions<T> = {
  key: string;
  default: T;
  paramType: QueryParamConfig<any>;
};

export const usePersistentQueryParam = <T = string>(
  options: PersistentQueryParamOptions<T>,
): [T, (val: T) => void] => {
  const [queryParam, setQueryParam] = useQueryParam<T>(
    options.key,
    options.paramType,
  );
  const queryParamRef = useRef(queryParam);
  const [localStorageValue, setLocalStorageValue] = useLocalStorage<
    T | undefined
  >(options.key, undefined);

  const setFunction = useCallback(
    (val: T) => {
      if (!isEqual(queryParam, val)) {
        setQueryParam(val, "replaceIn");
      }
      if (!isEqual(localStorageValue, val)) {
        setLocalStorageValue(val);
      }
      if (!isEqual(queryParamRef.current, val)) {
        queryParamRef.current = val;
      }
      setState(val);
    },
    [localStorageValue, queryParam, setLocalStorageValue, setQueryParam],
  );

  const [state, setState] = useState<T>(
    queryParam || localStorageValue || options.default,
  );

  useEffect(() => {
    setFunction(state);
  });
  return [state, setFunction];
};
