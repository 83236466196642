import React, { ReactNode } from "react";
import { Box, Grid, Stack, Tooltip } from "@mui/material";
import {
  ClusterAnalyticsSummary,
  ClusterData,
} from "../Analytics/ClusterStatistics";
import { formatSeconds } from "../../../utils";
import { theme } from "../../../theme";
import { LiveChip } from "./LiveChip";
import { ClusterFrontendSchema } from "../../../api-client";

const InfoTooltip = ({ info, text }: { info: ReactNode; text?: any }) => (
  <Tooltip sx={{ verticalAlign: "middle" }} title={info}>
    <Box
      sx={{
        display: "inline",
        whiteSpace: "nowrap",
        borderBottom: `1px dotted ${theme.palette.text.primary}`,
      }}
    >
      {text}
    </Box>
  </Tooltip>
);

export const ClusterStatistics = ({
  cluster,
  clusterStatistics,
  analyticsSummary,
  clusterIsRunning,
}: {
  cluster?: ClusterFrontendSchema;
  clusterStatistics?: ClusterData;
  analyticsSummary?: ClusterAnalyticsSummary;
  clusterIsRunning?: boolean;
}): React.ReactElement => {
  const diskDurationTotal =
    (analyticsSummary?.durationsTotal.diskRead
      ? analyticsSummary?.durationsTotal.diskRead
      : 0) +
    (analyticsSummary?.durationsTotal.diskWrite
      ? analyticsSummary?.durationsTotal.diskWrite
      : 0);

  const diskDurationText = (
    <>
      ({analyticsSummary?.durationsTotal.diskRead!.toFixed(2)}s read +{" "}
      {analyticsSummary?.durationsTotal.diskWrite!.toFixed(2)}s write) / <br />
      {clusterStatistics?.thread_seconds.toFixed(2)}s Available Thread Time.
      <br />
      <br />
      This is the amount of time that Dask worker threads spent reading or
      writing to disk while running your tasks.
    </>
  );

  const computeDurationText = (
    <>
      {analyticsSummary?.durationsTotal.compute.toFixed(2)}
      s Compute Thread Time / <br />
      {clusterStatistics?.thread_seconds.toFixed(2)}s Available Thread Time.
      <br />
      <br />
      Compute Thread Time is the amount of time that Dask worker threads spent
      running your tasks.
      <br />
      <br />
      100% would mean that every Dask worker thread was running a computation
      for the entire time.
    </>
  );

  const idleText = (
    <>
      {clusterStatistics?.idle_timeout ? (
        <>
          {formatSeconds(clusterStatistics?.idle_timeout, 0, "short")} idle
          timeout
          <br />
        </>
      ) : (
        ""
      )}
      Idle since {clusterStatistics?.idle_since}
      <br />
      Last seen {clusterStatistics?.last_seen}
    </>
  );

  // TODO: Migrate legacy ClusterData type to use camelizeKeys
  const stats: [string, React.ReactElement][] = [
    ["Total Tasks", <>{clusterStatistics?.n_tasks}</>],
    [
      "Uptime",
      <>
        {cluster?.billableTime
          ? formatSeconds(cluster.billableTime, 0, "short")
          : ""}
      </>,
    ],
    [
      "Available Thread Time",
      <>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs>
            <InfoTooltip
              text={
                clusterStatistics?.thread_seconds &&
                formatSeconds(clusterStatistics?.thread_seconds, 0, "short")
              }
              info="Wall Time × Number of Threads"
            />
          </Grid>
          <Grid item xs>
            <Stack
              sx={{
                fontSize: "12px",
                lineHeight: "16px",
                whiteSpace: "nowrap",
              }}
            >
              {analyticsSummary?.durationsTotal.compute &&
              clusterStatistics?.thread_seconds ? (
                <Grid container wrap="nowrap" spacing={0.5}>
                  <Grid item xs sx={{ color: theme.palette.custom.grey.dark }}>
                    Compute
                  </Grid>
                  <Grid item xs="auto" sx={{ textAlign: "right" }}>
                    <InfoTooltip
                      text={`${(
                        (analyticsSummary?.durationsTotal.compute /
                          clusterStatistics?.thread_seconds) *
                        100
                      ).toFixed(2)}%`}
                      info={computeDurationText}
                    />
                  </Grid>
                </Grid>
              ) : null}
              {analyticsSummary?.durationsTotal.compute &&
              clusterStatistics?.thread_seconds ? (
                <Grid container wrap="nowrap" spacing={0.5}>
                  <Grid item xs sx={{ color: theme.palette.custom.grey.dark }}>
                    Disk
                  </Grid>
                  <Grid item xs="auto" sx={{ textAlign: "right" }}>
                    <InfoTooltip
                      text={`${(
                        (diskDurationTotal /
                          clusterStatistics?.thread_seconds) *
                        100
                      ).toFixed(2)}%`}
                      info={diskDurationText}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Stack>
          </Grid>
        </Grid>
      </>,
    ],
    [
      "Used Credits",
      <>
        {cluster?.costPerHour ? (
          <InfoTooltip
            text={cluster?.totalCost?.toFixed(2)}
            info={`Current rate: ${cluster?.costPerHour?.toFixed(
              2,
            )} credits/hour`}
          />
        ) : (
          cluster?.totalCost?.toFixed(2)
        )}
      </>,
    ],
    [
      "Last Idle For",
      <>
        {clusterStatistics?.idle_duration ? (
          <InfoTooltip
            text={formatSeconds(clusterStatistics?.idle_duration, 0, "short")}
            info={idleText}
          />
        ) : (
          "–"
        )}
      </>,
    ],
  ];

  return (
    <Grid container spacing={5} wrap="nowrap">
      {stats.map(([label, value]) => (
        <Grid item key={label}>
          <Box
            sx={{
              color: theme.palette.custom.grey.dark,
              fontSize: theme.typography.pxToRem(14),
              lineHeight: theme.typography.pxToRem(20),
              mb: 0.5,
            }}
          >
            {label}
          </Box>
          <Box
            sx={{
              color: theme.palette.text.primary,
              fontSize: theme.typography.pxToRem(20),
              fontWeight: "500",
              lineHeight: theme.typography.pxToRem(28),
            }}
          >
            {value}
          </Box>
        </Grid>
      ))}
      <Grid item xs sx={{ display: "flex", justifyContent: "flex-end" }}>
        {clusterIsRunning ? <LiveChip /> : null}
      </Grid>
    </Grid>
  );
};
