/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArchitectureTypesEnum } from './ArchitectureTypesEnum';
import {
    ArchitectureTypesEnumFromJSON,
    ArchitectureTypesEnumFromJSONTyped,
    ArchitectureTypesEnumToJSON,
} from './ArchitectureTypesEnum';
import type { CreatePackageSchema } from './CreatePackageSchema';
import {
    CreatePackageSchemaFromJSON,
    CreatePackageSchemaFromJSONTyped,
    CreatePackageSchemaToJSON,
} from './CreatePackageSchema';

/**
 * 
 * @export
 * @interface CreateSoftwareEnvironmentSpecSchema
 */
export interface CreateSoftwareEnvironmentSpecSchema {
    /**
     * 
     * @type {string}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    forceRebuild?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    gpuEnabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    rawPip?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    rawConda?: object;
    /**
     * 
     * @type {string}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    md5?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    container?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    useEntrypoint?: boolean;
    /**
     * 
     * @type {Array<CreatePackageSchema>}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    packages?: Array<CreatePackageSchema>;
    /**
     * 
     * @type {ArchitectureTypesEnum}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    architecture?: ArchitectureTypesEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    regionName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateSoftwareEnvironmentSpecSchema
     */
    enableExperimentalInstaller?: boolean;
}

/**
 * Check if a given object implements the CreateSoftwareEnvironmentSpecSchema interface.
 */
export function instanceOfCreateSoftwareEnvironmentSpecSchema(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CreateSoftwareEnvironmentSpecSchemaFromJSON(json: any): CreateSoftwareEnvironmentSpecSchema {
    return CreateSoftwareEnvironmentSpecSchemaFromJSONTyped(json, false);
}

export function CreateSoftwareEnvironmentSpecSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSoftwareEnvironmentSpecSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'forceRebuild': !exists(json, 'force_rebuild') ? undefined : json['force_rebuild'],
        'gpuEnabled': !exists(json, 'gpu_enabled') ? undefined : json['gpu_enabled'],
        'rawPip': !exists(json, 'raw_pip') ? undefined : json['raw_pip'],
        'rawConda': !exists(json, 'raw_conda') ? undefined : json['raw_conda'],
        'md5': !exists(json, 'md5') ? undefined : json['md5'],
        'container': !exists(json, 'container') ? undefined : json['container'],
        'useEntrypoint': !exists(json, 'use_entrypoint') ? undefined : json['use_entrypoint'],
        'packages': !exists(json, 'packages') ? undefined : ((json['packages'] as Array<any>).map(CreatePackageSchemaFromJSON)),
        'architecture': !exists(json, 'architecture') ? undefined : ArchitectureTypesEnumFromJSON(json['architecture']),
        'regionName': !exists(json, 'region_name') ? undefined : json['region_name'],
        'enableExperimentalInstaller': !exists(json, 'enable_experimental_installer') ? undefined : json['enable_experimental_installer'],
    };
}

export function CreateSoftwareEnvironmentSpecSchemaToJSON(value?: CreateSoftwareEnvironmentSpecSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'force_rebuild': value.forceRebuild,
        'gpu_enabled': value.gpuEnabled,
        'raw_pip': value.rawPip,
        'raw_conda': value.rawConda,
        'md5': value.md5,
        'container': value.container,
        'use_entrypoint': value.useEntrypoint,
        'packages': value.packages === undefined ? undefined : ((value.packages as Array<any>).map(CreatePackageSchemaToJSON)),
        'architecture': ArchitectureTypesEnumToJSON(value.architecture),
        'region_name': value.regionName,
        'enable_experimental_installer': value.enableExperimentalInstaller,
    };
}

