import React from "react";
import { Legend } from "recharts";
import { ChartProps, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { GroupedLegend } from "./GroupedLegend";

export const WorkerMemoryChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Total (by type)",
      groupKey: "type",
      queries: [
        {
          name: "dask_memory&instance | sum_by(type)",
          sort: "type:spilled,managed,unmanaged",
        },
        {
          name: "host_memory_total&instance | sum",
          typeLabel: "host total memory",
        },
      ],
      domains: [
        { name: "managed", color: "#2159FF", stack: 0 },
        { name: "unmanaged", color: "#8fe38f", stack: 0 },
        { name: "spilled", color: "#e45756", stack: 0 },
        {
          name: "host total memory",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
    {
      name: "Distribution (by type)",
      groupKey: "type",
      queries: [
        {
          name: "managed_memory&instance | min",
          typeLabel: "min (managed)",
        },
        {
          name: "managed_memory&instance | max",
          typeLabel: "max (managed)",
        },
        {
          name: "managed_memory&instance | pct50",
          typeLabel: "median (managed)",
        },
        {
          name: "unmanaged_memory&instance | min",
          typeLabel: "min (unmanaged)",
        },
        {
          name: "unmanaged_memory&instance | max",
          typeLabel: "max (unmanaged)",
        },
        {
          name: "unmanaged_memory&instance | pct50",
          typeLabel: "median (unmanaged)",
        },
        {
          name: "spilled_memory&instance | min",
          typeLabel: "min (spilled)",
        },
        {
          name: "spilled_memory&instance | max",
          typeLabel: "max (spilled)",
        },
        {
          name: "spilled_memory&instance | pct50",
          typeLabel: "median (spilled)",
        },
      ],
      domains: [
        { name: "min (spilled)", color: null, stack: 0, range: 1 },
        { name: "max (spilled)", color: null, stack: 0, range: 1 },
        { name: "min (unmanaged)", color: null, stack: 0, range: 2 },
        { name: "max (unmanaged)", color: null, stack: 0, range: 2 },
        { name: "min (managed)", color: null, stack: 0, range: 3 },
        { name: "max (managed)", color: null, stack: 0, range: 3 },
        {
          name: "median (managed)",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 10,
        },
        {
          name: "median (unmanaged)",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 11,
        },
        {
          name: "median (spilled)",
          color: { stroke: "#e45756", fill: "white" },
          stack: 12,
        },
        {
          name: "min (managed)-max (managed)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
        {
          name: "min (unmanaged)-max (unmanaged)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "min (spilled)-max (spilled)",
          color: { stroke: "", fill: "#e45756", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Total (host memory)",
      groupKey: "type",
      queries: [
        {
          name: "all:(host_memory_total-host_memory_available)&instance | sum",
          typeLabel: "used",
        },
        {
          name: "all:host_memory_total | *0.6 | sum",
          typeLabel: "default spill target",
        },
        {
          name: "all:host_memory_total | sum",
          typeLabel: "cluster memory limit",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "cluster memory limit",
          color: { stroke: "#FF0000", fill: "white" },
          stack: 1,
        },
        {
          name: "default spill target",
          color: { stroke: "#FF7F00", fill: "white" },
          stack: 2,
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Distribution (host memory)",
      groupKey: "type",
      queries: [
        {
          name: "(host_memory_total-host_memory_available)&instance | min",
          typeLabel: "min",
        },
        {
          name: "(host_memory_total-host_memory_available)&instance | max",
          typeLabel: "max",
        },
        {
          name: "(host_memory_total-host_memory_available)&instance | pct20",
          typeLabel: "pct20",
        },
        {
          name: "(host_memory_total-host_memory_available)&instance | pct80",
          typeLabel: "pct80",
        },
        {
          name: "(host_memory_total-host_memory_available)&instance | pct50",
          typeLabel: "median",
        },
        {
          name: "host_memory_total | min | *0.6",
          typeLabel: "default spill target",
        },
        {
          name: "host_memory_total | min",
          typeLabel: "host memory limit",
        },
      ],
      domains: [
        // min, max, pct20, pct80 won't be visible because the color is undefined
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "host memory limit",
          color: { stroke: "#FF0000", fill: "white" },
          stack: 10,
        },
        {
          name: "default spill target",
          color: { stroke: "#FF7F00", fill: "white" },
          stack: 11,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Scheduler",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:(host_memory_total-host_memory_available)&scheduler",
          typeLabel: "used",
        },
        {
          name: "scheduler:host_memory_total&scheduler",
          typeLabel: "total",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "#2159FF", opacity: 0.2 },
          stack: 1,
        },
        {
          name: "total",
          color: { stroke: "#000000", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Memory"
        variations={variations}
        {...props}
      />
    </>
  );
};

export const CombinedMemoryChart = (props: ChartProps): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Distribution (host memory)",
      groupKey: "type",
      queries: [
        {
          name: "all:(host_memory_total-host_memory_available)&instance | min",
          typeLabel: "min",
        },
        {
          name: "all:(host_memory_total-host_memory_available)&instance | max",
          typeLabel: "max",
        },
        {
          name: "all:(host_memory_total-host_memory_available)&instance | pct20",
          typeLabel: "pct20",
        },
        {
          name: "all:(host_memory_total-host_memory_available)&instance | pct80",
          typeLabel: "pct80",
        },
        {
          name: "all:(host_memory_total-host_memory_available)&instance | pct50",
          typeLabel: "median",
        },
        {
          name: "all:host_memory_total | min | *0.6",
          typeLabel: "default spill target",
        },
        {
          name: "all:host_memory_total | min",
          typeLabel: "host memory limit",
        },
      ],
      domains: [
        // min, max, pct20, pct80 won't be visible because the color is undefined
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "host memory limit",
          color: { stroke: "#FF0000", fill: "white" },
          stack: 10,
        },
        {
          name: "default spill target",
          color: { stroke: "#FF7F00", fill: "white" },
          stack: 11,
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Total (host memory)",
      groupKey: "type",
      queries: [
        {
          name: "all:(host_memory_total-host_memory_available)&instance | sum",
          typeLabel: "used",
        },
        {
          name: "all:host_memory_total | *0.6 | sum",
          typeLabel: "default spill target",
        },
        {
          name: "all:host_memory_total | sum",
          typeLabel: "cluster memory limit",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "cluster memory limit",
          color: { stroke: "#FF0000", fill: "white" },
          stack: 1,
        },
        {
          name: "default spill target",
          color: { stroke: "#FF7F00", fill: "white" },
          stack: 2,
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Distribution (by Dask memory type)",
      groupKey: "type",
      queries: [
        {
          name: "managed_memory&instance | min",
          typeLabel: "min (managed)",
        },
        {
          name: "managed_memory&instance | max",
          typeLabel: "max (managed)",
        },
        {
          name: "managed_memory&instance | pct50",
          typeLabel: "median (managed)",
        },
        {
          name: "unmanaged_memory&instance | min",
          typeLabel: "min (unmanaged)",
        },
        {
          name: "unmanaged_memory&instance | max",
          typeLabel: "max (unmanaged)",
        },
        {
          name: "unmanaged_memory&instance | pct50",
          typeLabel: "median (unmanaged)",
        },
        {
          name: "spilled_memory&instance | min",
          typeLabel: "min (spilled)",
        },
        {
          name: "spilled_memory&instance | max",
          typeLabel: "max (spilled)",
        },
        {
          name: "spilled_memory&instance | pct50",
          typeLabel: "median (spilled)",
        },
      ],
      domains: [
        { name: "min (spilled)", color: null, stack: 0, range: 1 },
        { name: "max (spilled)", color: null, stack: 0, range: 1 },
        { name: "min (unmanaged)", color: null, stack: 0, range: 2 },
        { name: "max (unmanaged)", color: null, stack: 0, range: 2 },
        { name: "min (managed)", color: null, stack: 0, range: 3 },
        { name: "max (managed)", color: null, stack: 0, range: 3 },
        {
          name: "median (managed)",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 10,
        },
        {
          name: "median (unmanaged)",
          color: { stroke: "#8fe38f", fill: "white" },
          stack: 11,
        },
        {
          name: "median (spilled)",
          color: { stroke: "#e45756", fill: "white" },
          stack: 12,
        },
        {
          name: "min (managed)-max (managed)",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
        {
          name: "min (unmanaged)-max (unmanaged)",
          color: { stroke: "", fill: "#8fe38f", opacity: 0.2 },
        },
        {
          name: "min (spilled)-max (spilled)",
          color: { stroke: "", fill: "#e45756", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Total (by Dask memory type)",
      groupKey: "type",
      queries: [
        {
          name: "dask_memory&instance | sum_by(type)",
          sort: "type:spilled,managed,unmanaged",
        },
        {
          name: "all:host_memory_total&instance | sum",
          typeLabel: "host total memory",
        },
      ],
      domains: [
        { name: "managed", color: "#2159FF", stack: 0 },
        { name: "unmanaged", color: "#8fe38f", stack: 0 },
        { name: "spilled", color: "#e45756", stack: 0 },
        {
          name: "host total memory",
          color: { stroke: "#908e89", fill: "white", opacity: 0 },
          stack: 1,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Memory"
        chartKey="memory_combined"
        variations={variations}
        {...props}
      />
    </>
  );
};

export const SingleMachineMemoryChart = (
  props: ChartProps,
): React.ReactElement => {
  const variations: Variation[] = [
    {
      name: "Host Memory",
      groupKey: "type",
      queries: [
        {
          name: "scheduler:(host_memory_total-host_memory_available)&scheduler",
          typeLabel: "used",
        },
        {
          name: "scheduler:host_memory_total&scheduler",
          typeLabel: "total",
        },
      ],
      domains: [
        {
          name: "used",
          color: { stroke: "#2159FF", fill: "#2159FF", opacity: 0.2 },
          stack: 1,
        },
        {
          name: "total",
          color: { stroke: "#000000", fill: "white", opacity: 0 },
          stack: 2,
        },
      ],
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Host Memory"
        variations={variations}
        {...props}
      />
    </>
  );
};
