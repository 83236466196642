/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProgramSchemaStaff
 */
export interface UpdateProgramSchemaStaff {
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchemaStaff
     */
    coreLimitMembers: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchemaStaff
     */
    coreLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchemaStaff
     */
    gpusLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchemaStaff
     */
    membersLimit: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchemaStaff
     */
    nodeLimit: number;
}

/**
 * Check if a given object implements the UpdateProgramSchemaStaff interface.
 */
export function instanceOfUpdateProgramSchemaStaff(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "coreLimitMembers" in value;
    isInstance = isInstance && "coreLimit" in value;
    isInstance = isInstance && "gpusLimit" in value;
    isInstance = isInstance && "membersLimit" in value;
    isInstance = isInstance && "nodeLimit" in value;

    return isInstance;
}

export function UpdateProgramSchemaStaffFromJSON(json: any): UpdateProgramSchemaStaff {
    return UpdateProgramSchemaStaffFromJSONTyped(json, false);
}

export function UpdateProgramSchemaStaffFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProgramSchemaStaff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'coreLimitMembers': json['core_limit_members'],
        'coreLimit': json['core_limit'],
        'gpusLimit': json['gpus_limit'],
        'membersLimit': json['members_limit'],
        'nodeLimit': json['node_limit'],
    };
}

export function UpdateProgramSchemaStaffToJSON(value?: UpdateProgramSchemaStaff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'core_limit_members': value.coreLimitMembers,
        'core_limit': value.coreLimit,
        'gpus_limit': value.gpusLimit,
        'members_limit': value.membersLimit,
        'node_limit': value.nodeLimit,
    };
}

