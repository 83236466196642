import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Typography,
  Button,
  Stack,
  CardContent,
  LinearProgress,
  Skeleton,
  Box,
  styled,
  Link,
  Tab,
  Container,
  Card,
  Divider,
  useTheme,
  Stepper,
  Step,
  StepContent,
  StepButton,
  ListItem,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Urls } from "../../domain/urls";
import LocalPhoneRoundedIcon from "@mui/icons-material/LocalPhoneRounded";
import {
  AllFlags,
  InteractionFlagsContext,
} from "../../crud/interactions/context";
import { CodeBlock } from "../../shared-components/CodeBlock";
import {
  CheckCircle,
  Cloud,
  Computer,
  Functions,
  Login,
  QueryStats,
  Terminal,
} from "@mui/icons-material";
import { WorkspaceContext } from "../../utils/context";
import { TabPanelProps } from "@mui/base";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DaskIcon } from "../../icons/DaskIcon";
import { JupyterIcon } from "../../icons/JupyterIcon";
import { TeamIcon } from "../../icons/TeamIcon";
import { InteractionFlagNames } from "../../crud/interactions/types";
import { TitleCard } from "../../shared-components/Cards";
import List from "@mui/material/List";
import { useProgram, useProgramUsage } from "../../crud/pricing/hooks";
import { ProgramChoicesEnum } from "../../api-client";
import { calendlyLink } from "../../utils";

const CustomTabPanel = styled(TabPanel)<TabPanelProps>(({ theme }) => ({
  backgroundColor: theme.palette.custom.white,
  paddingLeft: 0,
  paddingBottom: 0,
}));

enum ValidTabs {
  dask = "dask",
  cli = "cli",
  notebooks = "notebooks",
  functions = "functions",
}

type StepperButtonProps = {
  headerIcon: React.ReactNode;
  header: string;
  subtitle: string | React.ReactNode;
  complete?: boolean;
  button: React.ReactNode;
};

const TutorialTabs = (): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState<ValidTabs>(ValidTabs.cli);
  return (
    <TabContext value={selectedTab}>
      <div>
        <TabList
          variant="fullWidth"
          onChange={(e, newValue) => setSelectedTab(newValue)}
        >
          <Tab
            icon={<Terminal />}
            label={"CLI Jobs"}
            iconPosition="start"
            value={ValidTabs.cli}
          />
          <Tab
            icon={<Functions />}
            iconPosition="start"
            label={"Serverless Functions"}
            value={ValidTabs.functions}
          />
          <Tab
            icon={<DaskIcon />}
            label={"Dask Clusters"}
            iconPosition="start"
            value={ValidTabs.dask}
          />
          <Tab
            icon={<JupyterIcon />}
            label={"Jupyter Notebooks"}
            iconPosition="start"
            value={ValidTabs.notebooks}
          />
        </TabList>
        <CustomTabPanel value={ValidTabs.dask}>
          <Stack spacing={2}>
            <Typography>
              Dask clusters provide flexible, large-scale computation with
              hundreds of different libraries.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled
cluster = coiled.Cluster(n_workers=10)
client = cluster.get_client()
`}
            />
            <CodeBlock
              language="python"
              snippet={`import dask

# generate random timeseries of data
df = dask.datasets.timeseries(
    "2000", "2005", partition_freq="2W"
).persist()

# perform a groupby with an aggregation
df.groupby("name").aggregate({"x": "sum", "y": "max"}).compute()`}
            />
          </Stack>
          <Typography>
            More examples:
            <List>
              <ListItem>
                <Link href="https://docs.coiled.io/user_guide/usage/dask/dataframes.html">
                  DataFrame/ETL Examples
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://docs.coiled.io/user_guide/usage/dask/ml.html">
                  Machine Learning Examples
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://docs.coiled.io/user_guide/usage/dask/geospatial.html">
                  Geospatial Examples
                </Link>
              </ListItem>
            </List>
          </Typography>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.cli}>
          <Stack spacing={2}>
            <Typography>
              Easily create a cloud VM, run a program, and destroy that VM, all
              from the command line:
            </Typography>
            <CodeBlock
              language="shell"
              snippet={`coiled run echo "Hello, world"`}
            />
            <Typography>
              For more information, see the{" "}
              <Link href="https://docs.coiled.io/user_guide/labs/jobs.html">
                Coiled Run documentation.
              </Link>
            </Typography>
            <Typography>{"Here's a more complex example:"}</Typography>
            <CodeBlock
              language="shell"
              snippet={`coiled run echo "Hello, world" --region us-west-2 --vm-type m7i.16xlarge`}
            />
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.functions}>
          <Stack spacing={2}>
            <Typography>
              Decorate Python functions to run them in the cloud:
            </Typography>
            <CodeBlock
              language="python"
              snippet={`import coiled, random

@coiled.function()
def estimate_pi(n: int) -> float:
    total = 0
    for _ in range(n):
        x = random.random()
        y = random.random()
        if x ** 2 + y ** 2 < 1:
            total += 1
    return total / n * 4

pi = estimate_pi(100_000)
print(pi)`}
            />
            <Typography>
              Scale out using the map method. You will see new machines in about
              a minute and things will finish in around three minutes.
            </Typography>
            <CodeBlock
              language="python"
              snippet={`L = list(estimate_pi.map([10_000_000] * 1000))
pi = sum(L) / len(L)
print(pi)
`}
            />
            <Typography>
              You can use these from anywhere you run Python locally, including
              Python scripts, IPython, or Jupyter notebooks.
              <br /> More examples:
              <List>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/duckdb.html">
                    Using DuckDB on a big VM
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/pytorch.html">
                    Using PyTorch on a GPU
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://docs.coiled.io/user_guide/usage/functions/sklearn.html">
                    Using scikit-learn on a compute-optimized VM
                  </Link>
                </ListItem>
              </List>
            </Typography>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={ValidTabs.notebooks}>
          <Typography>
            Start a Jupyter server on a big cloud machine:
          </Typography>
          <CodeBlock
            language="shell"
            snippet={"coiled notebook start --cpu 64 --memory '256 GB'"}
          />
          <Typography>Or if you want a GPU then try this:</Typography>
          <CodeBlock
            language="shell"
            snippet={
              "coiled notebook start --vm-type g5.xlarge --container coiled/gpu-examples:latest --region us-west-2"
            }
          />
          <Typography>
            {"To turn on real-time file synchronization, add the "}
            <code>--sync</code>
            {" flag (you'll be prompted to install mutagen):"}
            <CodeBlock
              language="shell"
              snippet={"coiled notebook start --sync"}
            />
          </Typography>
          <Typography>
            For more information, see the{" "}
            <Link href="https://docs.coiled.io/user_guide/labs/notebooks.html">
              Coiled Notebooks documentation.
            </Link>
          </Typography>
        </CustomTabPanel>
      </div>
    </TabContext>
  );
};
const StepperButton = ({
  headerIcon,
  header,
  subtitle,
  complete,

  button,
}: StepperButtonProps): React.ReactElement => {
  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        {complete ? <CheckCircle color="success" /> : headerIcon}
        <Stack>
          <Typography variant="h3">{header}</Typography>
          <Typography
            variant="subtitle2"
            color={(theme) => theme.palette.grey[700]}
          >
            {subtitle}
          </Typography>
        </Stack>
        {!complete && (
          <Box sx={{ marginLeft: "auto !important" }}>{button}</Box>
        )}
      </Stack>
    </Stack>
  );
};

const TASKS = [
  {
    key: InteractionFlagNames.HasApiKey,
    title: "Step 1. Authenticate your computer",
    subtitle: "Set up an API token on your computer so it can access Coiled",
    content: (
      <CodeBlock
        language="shell"
        snippet={`pip install coiled "dask[complete]"\ncoiled login`}
      />
    ),
    icon: <Login />,
  },
  {
    key: InteractionFlagNames.AccessToAccountWithCompletedSetup,
    title: "Step 2. Connect your cloud",
    subtitle:
      "Grant Coiled access to your AWS, Google Cloud, or Azure account.",
    completedSubtitle:
      "You're already in a Coiled workspace that's connected to the cloud",
    icon: <Cloud />,
    content: (
      <Button
        variant="primary"
        component={RouterLink}
        to={`/${Urls.Setup}/${Urls.Credentials}`}
        sx={{ width: "130px" }}
      >
        Begin Setup
      </Button>
    ),
    completedContent: (
      <Typography>
        There’s no need to set up your cloud provider because you already have
        access to a Coiled workspace that’s connected to the cloud!
      </Typography>
    ),
  },
  {
    key: InteractionFlagNames.HasCreatedCluster,
    title: "Step 3. Use Coiled",
    subtitle: `Get started with driving the cloud from your computer.`,
    icon: <Computer />,
    content: <TutorialTabs />,
  },
];
const UsageSegment = ({
  programId,
}: {
  programId: number;
}): React.ReactElement => {
  const {
    data: program,
    isSuccess: programSuccess,
    isLoading: programLoading,
  } = useProgram(programId);
  const {
    data: programUsage,
    isSuccess: programUsageSuccess,
    isLoading: programUsageLoading,
  } = useProgramUsage(programId);
  const theme = useTheme();
  return (
    <StepperButton
      header="Keep an eye on your usage"
      headerIcon={<QueryStats />}
      subtitle={
        <Box sx={{ paddingTop: theme.spacing(1) }}>
          {programLoading || (programUsageLoading && <Skeleton />)}
          {programSuccess && programUsageSuccess && (
            <Stack alignItems={"flex-start"}>
              <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                  variant="determinate"
                  sx={{
                    height: 10,
                    width: 300,
                    borderRadius: 1,
                    backgroundColor: "success",
                  }}
                  color="primary"
                  value={programUsage.programCreditsUsagePercent}
                />
              </Box>
              <Box sx={{ minWidth: 150 }}>
                <Typography variant="subtitle1" color="text.secondary">
                  {programUsage.programCreditsSpent.toLocaleString(undefined, {
                    maximumFractionDigits: 0,
                  })}
                  {"/"}
                  {program.tier === ProgramChoicesEnum.Free
                    ? `${program.creditAllotment.toLocaleString(undefined, {
                        maximumFractionDigits: 0,
                      })}  free credits`
                    : "∞"}
                  {}
                </Typography>
              </Box>
            </Stack>
          )}
        </Box>
      }
      button={
        <Button
          variant="primary"
          sx={{ maxHeight: "50px", minWidth: "200px " }}
          component={RouterLink}
          to={"/" + Urls.Billing}
        >
          Explore Billing
        </Button>
      }
    />
  );
};

export const GetStarted = (): React.ReactElement => {
  const viewedAccount = useContext(WorkspaceContext);
  const data = useContext(InteractionFlagsContext);
  const getNextIncompleteStep = useCallback(() => {
    const index = TASKS.findIndex((task) => !data[task.key].completed);
    return index === -1 ? TASKS.length - 1 : index;
  }, [data]);
  const [activeStep, setActiveStep] = useState<number>(getNextIncompleteStep());
  const incompleteStepsIndexes = TASKS.reduce<number[]>(
    (acc, task, index) => (data[task.key].completed ? acc : [...acc, index]),
    [],
  );
  const [expandedSteps, setExpandedSteps] = useState<number[]>(
    incompleteStepsIndexes,
  );

  const toggleStepExpansion = useCallback(
    (index: number) => {
      setExpandedSteps((prev) => {
        if (prev.includes(index)) {
          return prev.filter((i) => i !== index);
        } else {
          return [...prev, index];
        }
      });
    },
    [setExpandedSteps],
  );

  const activateNextIncompleteStep = useCallback(() => {
    TASKS.some((task, index) => {
      if (!data[task.key].completed) {
        setActiveStep(index);
        return true;
      } else if (index === TASKS.length - 1) {
        setActiveStep(index);
        return true;
      }
    });
  }, [data, setActiveStep]);

  const dataRef = useRef<AllFlags | undefined>();
  useEffect(() => {
    if (dataRef.current !== data) {
      if (dataRef.current === undefined) {
        activateNextIncompleteStep();
      } else {
        TASKS.forEach((task, index) => {
          if (
            dataRef.current &&
            !dataRef.current[task.key].completed &&
            data[task.key].completed
          ) {
            activateNextIncompleteStep();
            toggleStepExpansion(index);
          }
        });
      }
      dataRef.current = data;
    }
  }, [data, activateNextIncompleteStep, toggleStepExpansion]);

  const theme = useTheme();
  return (
    <Container maxWidth="xl">
      <Stack spacing={2}>
        <TitleCard
          title="Getting started"
          cardContentProps={{
            sx: {
              paddingBottom: `${theme.spacing(1)} !important`,
              marginBottom: 0,
            },
          }}
        >
          <Stack spacing={2}>
            <Typography>
              Welcome to Coiled! One of our engineers would be happy to help you
              get started (<Link href={calendlyLink}>schedule here</Link>
              !).
            </Typography>
            <Typography>Here are the three steps:</Typography>
            <Stepper orientation="vertical" nonLinear>
              {TASKS.map((step, index) => (
                <Step
                  key={step.key}
                  completed={data[step.key].completed || true}
                  active={activeStep === index}
                  expanded={expandedSteps.includes(index)}
                  sx={{ opacity: index === activeStep ? 1 : 0.4 }}
                >
                  <StepButton
                    onClick={() => toggleStepExpansion(index)}
                    icon={
                      data[step.key].completed ? (
                        <CheckCircle color="success" />
                      ) : (
                        step.icon
                      )
                    }
                    optional={
                      data[step.key].completed && step.completedSubtitle
                        ? step.completedSubtitle
                        : step.subtitle
                    }
                  >
                    {activeStep === index ? (
                      <strong>{step.title}</strong>
                    ) : (
                      step.title
                    )}
                  </StepButton>
                  <StepContent>
                    <Box sx={{ mb: 2 }}>
                      <div>
                        {data[step.key].completed &&
                        step &&
                        step.completedContent
                          ? step.completedContent
                          : step.content}
                      </div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </TitleCard>
        <Card>
          <CardContent
            sx={{
              paddingBottom: `${theme.spacing(1)} !important`,
              marginBottom: 0,
              opacity: data[InteractionFlagNames.HasCreatedCluster].completed
                ? 1
                : 0.4,
            }}
          >
            <Stack spacing={2}>
              <Box>
                <Typography variant="h2">Optional</Typography>
                <Typography variant="subtitle2">
                  Explore other features of Coiled.
                </Typography>
              </Box>
              <Divider />
              <StepperButton
                header="Talk to an Engineer"
                headerIcon={<LocalPhoneRoundedIcon />}
                subtitle="Come tell us about your problems. It's like therapy, but nerdier."
                button={
                  <Button
                    variant="primary"
                    component={RouterLink}
                    to={calendlyLink}
                    sx={{
                      minWidth: "200px",
                    }}
                  >
                    Schedule Time
                  </Button>
                }
              />
              <StepperButton
                header="Add Teammates"
                headerIcon={<TeamIcon />}
                subtitle="Add teammates to your workspace. They can use the cloud account you've setup."
                button={
                  <Button
                    variant="primary"
                    component={RouterLink}
                    to={"/" + Urls.Team}
                    sx={{
                      minWidth: "200px",
                    }}
                  >
                    Invite Teammates
                  </Button>
                }
              />
              {viewedAccount.activeProgramId && (
                <>
                  <Divider />
                  <UsageSegment programId={viewedAccount.activeProgramId} />
                </>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  );
};
