import React, { useMemo } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Page } from "../../shared-components/Page";
import { LocalizationProvider } from "@mui/x-date-pickers/";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { StaffMainView } from "./main";
import { ProgramEditForm } from "./ProgramEditForm";

const ProgramDetailView = (): React.ReactElement => {
  const programId = useParams().programId;
  if (!programId) {
    throw new Error("Program ID is required");
  }
  return <ProgramEditForm programId={Number(programId)} />;
};

export const StaffRouter = (): React.ReactElement => {
  const breadCrumbs = useMemo(() => [{ text: "Staff" }], []);
  return (
    <Page title="Staff" breadCrumbs={breadCrumbs}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes>
          <Route path="program/:programId" element={<ProgramDetailView />} />
          <Route path="*" element={<StaffMainView />} />
        </Routes>
      </LocalizationProvider>
    </Page>
  );
};
