import React, { useEffect } from "react";
import {
  Button,
  Divider,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useFieldArray, useForm } from "react-hook-form";
import {
  useAddOrganizationMemberMutation,
  useOrganization,
  useOrganizationMembershipDetails,
  useUpdateOrganizationMembershipMutation,
} from "../../crud/organizations/hooks";
import { useWorkspaces } from "../../crud/workspaces/hooks";
import { HookCheck } from "../../shared-components/HookCheck";
import {
  AddUserToWorkspaceSchema,
  OrganizationRoles,
  OrganizationSchema,
  WorkspaceLightWithMembershipSchema,
} from "../../api-client";
import { Urls } from "../../domain/urls";
import { useSnackbar } from "notistack";
import { TitleCard } from "../../shared-components/Cards";
import {
  useOrganizationInviteDetails,
  useUpdateOrganizationInviteMutation,
} from "../../crud/invites/hooks";
import { useIsAnyKindOfAdmin, useIsOrgAdmin } from "../../crud/account/hooks";
import { useScopedContext } from "../../shared-components/ScopeSelector";
import { LoadingButton } from "@mui/lab";

type WorkspaceFormProps = {
  id?: number;
  workspaceId: number;
  access: boolean;
  coreLimit: number;
  creditLimit: number;
  admin: boolean;
};

type OrganizationMemberFormProps = {
  id?: number;
  organizationId: number;
  usernameOrEmail: string;
  admin: boolean;
  workspaces: WorkspaceFormProps[];
};

export const OrganizationMemberForm = ({
  organizationId,
  membershipId,
  type,
}: {
  organizationId: number;
  membershipId?: number;
  type: "Invite" | "Membership";
}): React.ReactElement => {
  const { data: organization, isSuccess: orgLoaded } =
    useOrganization(organizationId);
  const {
    data: organizationWorkspaces,
    isSuccess: organizationWorkspacesLoaded,
  } = useWorkspaces({ organizationId });
  return (
    <>
      {orgLoaded || (organizationWorkspacesLoaded && <Skeleton />)}
      {orgLoaded && organizationWorkspacesLoaded && (
        <OrganizationMemberFormInner
          organization={organization}
          workspaces={organizationWorkspaces}
          membershipId={membershipId}
          type={type}
        />
      )}
    </>
  );
};

const WorkspaceRow = ({
  workspaceName,
  workspace,
  organizationId,
  register,
  index,
  control,
  errors,
}: {
  workspace: WorkspaceFormProps;
  workspaceName?: string;
  organizationId: number;
  register: any;
  control: any;
  index: number;
  errors: any;
}): React.ReactElement => {
  const isAdmin = useIsAnyKindOfAdmin(organizationId, [workspace.workspaceId]);
  return (
    <Tooltip title={isAdmin ? "" : `You are not an admin of ${workspaceName}`}>
      <TableRow key={workspace.workspaceId}>
        <TableCell>
          <HookCheck
            control={control}
            disabled={!isAdmin}
            size="small"
            name={`workspaces.${index}.access`}
            label={workspaceName}
          />
        </TableCell>
        <TableCell>
          <TextField
            size="small"
            type="number"
            disabled={!isAdmin}
            InputLabelProps={{ shrink: true }}
            {...register(`workspaces.${index}.creditLimit`)}
            label={"Credit Limit"}
          />
        </TableCell>
        <TableCell>
          <TextField
            type="number"
            size="small"
            disabled={!isAdmin}
            InputLabelProps={{ shrink: true }}
            {...register(`workspaces.${index}.coreLimit`)}
            label={"Core Limit"}
          />
        </TableCell>
        <TableCell>
          <HookCheck
            control={control}
            size="small"
            disabled={!isAdmin}
            name={`workspaces.${index}.admin`}
            label="Admin"
          />
        </TableCell>
      </TableRow>
    </Tooltip>
  );
};

const OrganizationMemberFormInner = ({
  workspaces,
  organization,
  membershipId,
  type,
}: {
  workspaces: WorkspaceLightWithMembershipSchema[];
  organization: OrganizationSchema;
  membershipId?: number;
  type: "Invite" | "Membership";
}): React.ReactElement => {
  const [, setGlobalScope] = useScopedContext();
  const { data: existingOrgMembership, isSuccess: membershipLoaded } =
    useOrganizationMembershipDetails(
      type === "Membership" ? membershipId : undefined,
    );
  const { data: existingInvite, isSuccess: inviteLoaded } =
    useOrganizationInviteDetails(type === "Invite" ? membershipId : undefined);
  const isOrgAdmin = useIsOrgAdmin(organization.id);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    register,
    handleSubmit,
    setError,
    control,
    reset,
    formState: { errors },
  } = useForm<OrganizationMemberFormProps>({
    defaultValues: {
      id: 0,
      organizationId: organization.id,
      usernameOrEmail: "",
      admin: false,
      workspaces: workspaces.map<WorkspaceFormProps>((workspace) => ({
        workspaceId: workspace.id,
        access: workspaces.length === 1 ? true : false,
        coreLimit: 1000,
        creditLimit: 100000,
        admin: false,
      })),
    },
  });

  const { fields: workspaceFields } = useFieldArray({
    control,
    name: "workspaces",
  });
  const { mutate: addOrganizationMember, isLoading: addOrgMemberLoading } =
    useAddOrganizationMemberMutation();
  const {
    mutate: patchOrganizationInvite,
    isLoading: patchInviteMemberLoading,
  } = useUpdateOrganizationInviteMutation();
  const { mutate: patchOrganizationMember, isLoading: patchOrgMemberLoading } =
    useUpdateOrganizationMembershipMutation();
  const { data: organizationWorkspaces } = useWorkspaces({
    organization: organization?.name,
  });
  useEffect(() => {
    if (type === "Membership" && existingOrgMembership) {
      reset({
        id: existingOrgMembership.id,
        organizationId: organization.id,
        usernameOrEmail: existingOrgMembership.user.username,
        admin: existingOrgMembership.role === OrganizationRoles.Admin,
        workspaces: workspaces.map<WorkspaceFormProps>((workspace) => {
          const existingWorkspaceMembership =
            existingOrgMembership.workspaceMemberships.find(
              (w) => w.workspaceId === workspace.id,
            );
          return {
            id: existingWorkspaceMembership?.id,
            workspaceId: workspace.id,
            access: existingWorkspaceMembership ? true : false,
            coreLimit: existingWorkspaceMembership?.coreLimit || 1000,
            creditLimit: existingWorkspaceMembership?.creditLimit || 100000,
            admin: !!existingWorkspaceMembership?.isAdmin,
          };
        }),
      });
    } else if (type === "Invite" && existingInvite) {
      reset({
        id: existingInvite.id,
        organizationId: organization.id,
        usernameOrEmail: existingInvite.invite.email,
        admin: false,
        workspaces: workspaces.map<WorkspaceFormProps>((workspace) => {
          const inviteForWorkspace = existingInvite.workspaceInvites.find(
            (w) => w.workspaceId === workspace.id,
          );
          return {
            id: inviteForWorkspace?.id,
            workspaceId: workspace.id,
            access: inviteForWorkspace ? true : false,
            coreLimit: 1000,
            creditLimit: 100000,
            admin: false,
          };
        }),
      });
    }
  }, [
    existingOrgMembership,
    reset,
    organization,
    workspaces,
    existingInvite,
    type,
  ]);

  return (
    <TitleCard title={`${membershipId ? "Edit" : "Add"} Member`}>
      <form
        onSubmit={handleSubmit((data) => {
          if (data.id) {
            if (type === "Invite") {
              patchOrganizationInvite(
                {
                  inviteId: data.id,
                  updateOrganizationInviteSchema: {
                    role: data.admin
                      ? OrganizationRoles.Admin
                      : OrganizationRoles.Member,
                    workspaces: data.workspaces
                      .filter((workspace) => workspace.access)
                      .map<AddUserToWorkspaceSchema>((workspace) => ({
                        id: workspace.id,
                        workspaceId: workspace.workspaceId,
                        isAdmin: workspace.admin,
                        coreLimit: workspace.coreLimit,
                        creditLimit: workspace.creditLimit,
                      })),
                  },
                },
                {
                  onError: async (err) => {
                    const { message } = await err.response.json();
                    enqueueSnackbar(`Error: ${message}`, {
                      variant: "error",
                    });
                  },
                  onSuccess: () => {
                    setGlobalScope({
                      type: "organization",
                      id: organization.id,
                      name: organization.name,
                    });
                    navigate(`/${Urls.Team}`);
                  },
                },
              );
            } else if (type === "Membership") {
              patchOrganizationMember(
                {
                  membershipId: data.id,
                  updateOrganizationMembershipSchema: {
                    role: data.admin
                      ? OrganizationRoles.Admin
                      : OrganizationRoles.Member,
                    workspaces: data.workspaces
                      .filter((w) => w.access)
                      .map<AddUserToWorkspaceSchema>((workspace) => ({
                        id: workspace.id,
                        workspaceId: workspace.workspaceId,
                        isAdmin: workspace.admin,
                        coreLimit: workspace.coreLimit,
                        creditLimit: workspace.creditLimit,
                      })),
                  },
                },
                {
                  onError: async (err) => {
                    const { message } = await err.response.json();
                    enqueueSnackbar(`Error: ${message}`, {
                      variant: "error",
                    });
                  },
                  onSuccess: () => {
                    setGlobalScope({
                      type: "organization",
                      id: organization.id,
                      name: organization.name,
                    });
                    navigate(`/${Urls.Team}`);
                  },
                },
              );
            }
          } else {
            if (type === "Invite") {
              throw new Error("Cannot create an invite?");
            }
            addOrganizationMember(
              {
                emailOrUsername: data.usernameOrEmail,
                organizationId: data.organizationId,
                role: data.admin
                  ? OrganizationRoles.Admin
                  : OrganizationRoles.Member,
                workspaces: data.workspaces
                  .filter((workspace) => workspace.access)
                  .map<AddUserToWorkspaceSchema>((workspace) => ({
                    workspaceId: workspace.workspaceId,
                    isAdmin: workspace.admin,
                    coreLimit: workspace.coreLimit,
                    creditLimit: workspace.creditLimit,
                  })),
              },
              {
                onError: async (err) => {
                  const { message, code, membership_id } =
                    await err.response.json();
                  if (err.response.status === 409) {
                    navigate(
                      `/${Urls.Team}/${organization.id}/edit-member/${membership_id}`,
                    );
                    enqueueSnackbar(
                      `User is already a member of this organization, we redirected you to the edit page to continue.`,
                      {
                        variant: "error",
                      },
                    );
                  } else if (code === "COULD_NOT_FIND_USER") {
                    setError("usernameOrEmail", {
                      type: "manual",
                      message,
                    });
                  } else {
                    enqueueSnackbar(`Error: ${message}`, {
                      variant: "error",
                    });
                  }
                },
                onSuccess: () => {
                  setGlobalScope({
                    type: "organization",
                    id: organization.id,
                    name: organization.name,
                  });
                  navigate(`/${Urls.Team}`);
                },
              },
            );
          }
        })}
      >
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" alignItems="start">
            <TextField
              {...register("usernameOrEmail", {
                required: "Username or Email is required",
              })}
              placeholder="steve@coiled.io"
              disabled={!!membershipId}
              size="small"
              sx={{ width: "500px" }}
              error={!!errors.usernameOrEmail}
              helperText={
                errors.usernameOrEmail?.message || "Username or Email"
              }
            />
            <HookCheck
              control={control}
              name="admin"
              size="small"
              label="Organization Admin"
              sx={{ maxWidth: "500px" }}
              disabled={!isOrgAdmin}
            />
          </Stack>
          <div>
            <Divider textAlign="left">
              <Typography variant="body1">Workspace Access</Typography>
            </Divider>
            <Table
              size="small"
              sx={{
                maxWidth: "1000px",
              }}
            >
              <TableBody>
                {((type === "Membership" &&
                  membershipId &&
                  !membershipLoaded) ||
                  (type === "Invite" && membershipId && !inviteLoaded)) && (
                  <TableRow>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                    <TableCell>
                      <Skeleton />
                    </TableCell>
                  </TableRow>
                )}
                {organizationWorkspaces &&
                  workspaceFields?.map((workspace, index) => (
                    <WorkspaceRow
                      workspaceName={
                        organizationWorkspaces.find(
                          (w) => w.id === workspace.workspaceId,
                        )?.name
                      }
                      key={workspace.id}
                      workspace={workspace}
                      organizationId={organization.id}
                      register={register}
                      index={index}
                      errors={errors}
                      control={control}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="start"
            width="100%"
          >
            <Button
              variant="secondary"
              component={RouterLink}
              to={`/${Urls.Team}`}
              onClick={() => {
                setGlobalScope({
                  type: "organization",
                  id: organization.id,
                  name: organization.name,
                });
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={
                patchInviteMemberLoading ||
                patchOrgMemberLoading ||
                addOrgMemberLoading
              }
            >
              {membershipId ? "Save" : "Add Member"}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </TitleCard>
  );
};
