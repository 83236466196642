import React, { useContext, useMemo } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCellOriginal from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";

import { styled, useTheme } from "@mui/material";
import copy from "clipboard-copy";
import { UsernameForm } from "./UsernameForm";
import { DefaultAccountForm } from "./DefaultAccountForm";
import { GithubKeyForm } from "./GithubKeyForm";
import { UserInfoForm } from "./UserInfoForm";
import { PasswordChangeform } from "./PasswordChangeForm";
import { CopyIcon } from "../../icons/CopyIcon";
import { EyeIcon } from "../../icons/EyeIcon";
import {
  ApiTokensSection,
  LONG_LIVED_TOKENS_ENABLED,
} from "./ApiTokensSection";
import { Page } from "../../shared-components/Page";
import { UserContext } from "../../crud/user/context";
import { useToken } from "../../utils/hooks";

const TableCell = styled(TableCellOriginal)({
  root: {
    border: "unset",
    padding: "8px",
  },
});

type TokenView = {
  viewing: "token" | "placeholder";
  token: string;
  placeholder: string;
};

export const Profile = (): React.ReactElement => {
  const theme = useTheme();
  const [copyConfirmOpen, setCopyConfirmOpen] = React.useState(false);
  const [userInfoFormOpen, setUserInfoFormOpen] = React.useState(false);
  const token = useToken();
  const { user, memberships } = useContext(UserContext);
  const [tokenView, setTokenView] = React.useState<TokenView>({
    viewing: "placeholder",
    token,
    placeholder: "\u2022".repeat(token.length),
  });

  const handleCopy = async () => {
    await copy(token);
    setCopyConfirmOpen(true);
  };
  const handleCopySnackbarClose = () => {
    setCopyConfirmOpen(false);
  };
  const toggleViewToken = () => {
    switch (tokenView.viewing) {
      case "placeholder":
        setTokenView({
          ...tokenView,
          viewing: "token",
        });
        break;
      default:
        setTokenView({
          ...tokenView,
          viewing: "placeholder",
        });
    }
  };

  const toggleUserInfoForm = () => {
    setUserInfoFormOpen(!userInfoFormOpen);
  };

  const {
    fullName,
    preferredName,
    profile: { title, company, workEmail, phoneNumber, canContact },
    hasUsablePassword,
  } = user;

  const emailType = hasUsablePassword ? "Email" : "Work Email";

  const profileData: [string, string | boolean][] = [
    ["Full Name", fullName],
    ["Preferred Name", preferredName || ""],
    ["Job Title", title],
    ["Company", company],
    [emailType, workEmail],
    ["Phone Number", phoneNumber],
    ["Can Contact", canContact ? "Yes" : "No"],
  ];
  const breadCrumbs = useMemo(() => [{ text: "Profile" }], []);
  return (
    <Page title="Profile" breadCrumbs={breadCrumbs}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Card>
            <CardHeader title="Default Workspace" />
            <Divider />
            <CardContent>
              <DefaultAccountForm />
            </CardContent>
          </Card>
        </Grid>
        {!LONG_LIVED_TOKENS_ENABLED && (
          <Grid item>
            <Card>
              <CardHeader title="Token" />
              <Divider />
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Tooltip title="copy token to clipboard">
                    <IconButton onClick={handleCopy} size="large">
                      {import.meta.env.FE_NEW_THEME === "true" ? (
                        <CopyIcon />
                      ) : (
                        <FileCopyIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="view token">
                    <IconButton onClick={toggleViewToken} size="large">
                      {import.meta.env.FE_NEW_THEME === "true" ? (
                        <EyeIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                  <pre
                    style={{
                      backgroundColor: "white",
                      fontSize: theme.typography.pxToRem(12),
                      padding: "4px",
                    }}
                  >
                    {tokenView[tokenView.viewing]}
                  </pre>
                </div>
                <Snackbar
                  open={copyConfirmOpen}
                  autoHideDuration={2000}
                  onClose={handleCopySnackbarClose}
                  message="Token copied to clipboard"
                />
              </CardContent>
            </Card>
          </Grid>
        )}

        {LONG_LIVED_TOKENS_ENABLED && (
          <Grid item>
            <Card>
              <CardHeader title="API Tokens" />
              <Divider />
              <CardContent>
                <div>
                  <ApiTokensSection />
                </div>
              </CardContent>
            </Card>
          </Grid>
        )}

        <Grid item>
          <Card>
            <CardHeader title="Username" />
            <Divider />
            <CardContent>
              <UsernameForm />
            </CardContent>
          </Card>
        </Grid>

        {user.hasUsablePassword && (
          <Grid item>
            <Card>
              <CardHeader title="Password" />
              <Divider />
              <CardContent>
                <PasswordChangeform />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <Card>
            <CardHeader title="Profile Information" />
            <Divider />
            <CardContent>
              <Table sx={{ marginBottom: "16px", maxWidth: "500px" }}>
                <TableBody>
                  {profileData.map(([left, right]) => {
                    return (
                      <TableRow key={left}>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            "&.MuiTableCell-root": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          {left}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: 600,
                            "&.MuiTableCell-root": {
                              borderBottom: "none",
                            },
                          }}
                        >
                          {right}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Button
                variant="secondary"
                onClick={toggleUserInfoForm}
                size="small"
              >
                Edit
              </Button>
              <UserInfoForm
                formTitle="Edit your profile"
                handleClose={toggleUserInfoForm}
                open={userInfoFormOpen}
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardHeader title="GitHub Access Token" />
            <Divider />
            <CardContent>
              <GithubKeyForm onSuccessfulSubmit={() => undefined} />
            </CardContent>
          </Card>
        </Grid>

        <Grid item>
          <Card>
            <CardHeader title="Accounts" />
            <Divider />
            <CardContent>
              <Table
                sx={{
                  maxWidth: "500px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <Tooltip
                      title={
                        "The total number of cores available to me in a workspace"
                      }
                      placement="top-start"
                    >
                      <TableCell>My core limit</TableCell>
                    </Tooltip>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memberships.map(({ account: { slug }, limit }) => (
                    <TableRow key={`org-${slug}`}>
                      <TableCell>{slug}</TableCell>
                      <TableCell>{limit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Page>
  );
};
