import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { ApiClient } from "../../apiUtils";
import {
  PricingNinjaViewsProgramDowngradeToFreeRequest,
  PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest,
  PricingNinjaViewsProgramUpdateProgramRequest,
  PricingNinjaViewsProgramUpdateProgramStaffRequest,
  PricingNinjaViewsProgramUpgradeToEnterpriseRequest,
  ProgramSchema,
  ProgramUsageSchema,
  ResponseError,
  WorkspaceProgramUsageSchema,
} from "../../api-client";

export const useWorkspaceProgramUsage = (
  query: PricingNinjaViewsProgramGetWorkspaceProgramUsageRequest,
): UseQueryResult<WorkspaceProgramUsageSchema> => {
  return useQuery(["getWorkspaceProgramUsage", query], () =>
    ApiClient.pricingNinjaViewsProgramGetWorkspaceProgramUsage(query),
  );
};

export const useProgramUsage = (
  programId?: number,
): UseQueryResult<ProgramUsageSchema> => {
  return useQuery(
    ["getProgramUsage", programId],
    () =>
      ApiClient.pricingNinjaViewsProgramGetProgramUsage({
        programId: programId as number,
      }),
    {
      enabled: !!programId,
    },
  );
};

export const useProgram = (
  programId?: number,
): UseQueryResult<ProgramSchema> => {
  return useQuery(
    ["getProgram", programId],
    () =>
      ApiClient.pricingNinjaViewsProgramGetProgram({
        programId: programId as number,
      }),
    {
      enabled: !!programId,
    },
  );
};

export const useUpdateProgramMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpdateProgramRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpdateProgram(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useUpdateProgramStaffMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpdateProgramStaffRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpdateProgramStaff(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useUpgradeToEnterpriseMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramUpgradeToEnterpriseRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramUpgradeToEnterprise(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};

export const useDowngradeToFreeMutation = (): UseMutationResult<
  ProgramSchema,
  ResponseError,
  PricingNinjaViewsProgramDowngradeToFreeRequest,
  unknown
> => {
  const client = useQueryClient();
  return useMutation(
    (data) => ApiClient.pricingNinjaViewsProgramDowngradeToFree(data),
    {
      onSuccess: (result) => {
        client.setQueryData(["getProgram", result.id], result);
      },
    },
  );
};
