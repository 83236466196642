import React from "react";
import { useOrganization } from "../../crud/organizations/hooks";
import { Page } from "../../shared-components/Page";
import { Urls } from "../../domain/urls";
import { OrganizationMemberForm } from "./OrganizationMemberForm";

export const AddOrganizationMemberForm = ({
  organizationId,
}: {
  organizationId: number;
}): React.ReactElement => {
  const { data: organization } = useOrganization(organizationId);
  return (
    <Page
      title="Add Member"
      breadCrumbs={[
        {
          text: "Team",
          link: `/${Urls.Team}`,
        },
        {
          text: organization?.name || "",
        },
        {
          text: "Members",
        },
        { text: "Add" },
      ]}
    >
      <OrganizationMemberForm
        organizationId={organizationId}
        type="Membership"
      />
    </Page>
  );
};
