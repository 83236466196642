import React, { ReactElement, useMemo } from "react";
import { GroupedMetric } from "../../../../crud/metrics/types";
import { TimeRange } from "../../types";
import { Box } from "@mui/system";
import { ZoomableAreaChart } from "./ZoomableAreaChart";
import { SamplingMethod } from "../sampleMetrics";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  Tooltip as RechartsTooltip,
} from "recharts";
import { theme } from "../../../../theme";
import { YAXIS_WIDTH } from "./const";
import { GroupedTooltip } from "./GroupedTooltip";
import { format } from "date-fns";

type LogsHistogramProps = {
  logs: GroupedMetric[];
  timeRange?: TimeRange;
  setTimeRange: (timeRange?: TimeRange) => void;
  defaultTimeRange?: TimeRange;
  filterPattern?: string;
  refAreaLeft: number;
  refAreaRight: number;
  setRefAreaLeft: (refAreaLeft: number) => void;
  setRefAreaRight: (refAreaRight: number) => void;
};

export const LogsHistogram = ({
  logs,
  timeRange,
  setTimeRange,
  defaultTimeRange,
  filterPattern,
  refAreaLeft,
  refAreaRight,
  setRefAreaLeft,
  setRefAreaRight,
}: LogsHistogramProps): ReactElement => {
  const histogramData = useMemo(() => getHistogramData(logs), [logs]);

  const domains = [
    {
      name: "info",
      color: "#9ecae9",
      stack: 1,
    },
    {
      name: "warning",
      color: "#eeca3b",
      stack: 1,
    },
    {
      name: "error",
      color: theme.palette.custom.red.main,
      stack: 1,
    },
  ];

  return (
    <Box
      sx={{
        pl: `${YAXIS_WIDTH}px`,
        "& .recharts-wrapper": {
          borderBottom: "1px solid rgb(25, 27, 31)",
        },
      }}
    >
      <ZoomableAreaChart
        data={histogramData}
        domains={domains}
        samplingMethod={SamplingMethod.SUM}
        isLoading={false}
        isError={false}
        Chart={BarChart}
        Cartesian={Bar}
        height={60}
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        defaultTimeRange={defaultTimeRange}
        refAreaLeft={refAreaLeft}
        refAreaRight={refAreaRight}
        setRefAreaLeft={setRefAreaLeft}
        setRefAreaRight={setRefAreaRight}
        legend={<div />}
        yAxis={<></>}
        xAxis={
          <XAxis
            allowDuplicatedCategory
            dataKey={({ timestamp }) => timestamp * 1000}
            tickFormatter={(v) => format(new Date(v), "HH:mm:ss")}
            hide
            allowDataOverflow
          />
        }
        tooltip={
          <RechartsTooltip
            content={
              <GroupedTooltip
                formatter={(value, name) => {
                  const formattedValue = `${Math.ceil(
                    value as unknown as number,
                  )} message${
                    Math.ceil(value as unknown as number) === 1 ? "" : "s"
                  }`;
                  return [formattedValue, name];
                }}
                labelFormatter={(value) =>
                  format(new Date(value), "LLL do, HH:mm:ss")
                }
              />
            }
            filterNull
            wrapperStyle={{
              outline: "none",
              zIndex: 2, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
            itemSorter={({ value }) => (value as number) * -1}
          />
        }
        cartesianGrid={
          <CartesianGrid stroke="none" fill="rgba(0, 0, 0, 0.04)" />
        }
      />
    </Box>
  );
};

/**
 * Crude way to split log messages into 3 categories: info, warning, error
 * Note: It would be nice to do this using a log level field
 */
const getHistogramData = (logs: GroupedMetric[]) =>
  logs.map((log) => {
    let key = "info";
    if (log.message.includes("- WARNING -")) {
      key = "warning";
    } else if (
      log.message.includes("- ERROR -") ||
      log.state?.includes("error")
    ) {
      key = "error";
    }
    return {
      timestamp: `${log.timestamp / 1000}`,
      [key]: 1,
    };
  });
