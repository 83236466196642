/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * An enumeration.
 * @export
 */
export const ProgramChoicesEnum = {
    Free: 'free',
    Payg: 'payg',
    Enterprise: 'enterprise'
} as const;
export type ProgramChoicesEnum = typeof ProgramChoicesEnum[keyof typeof ProgramChoicesEnum];


export function ProgramChoicesEnumFromJSON(json: any): ProgramChoicesEnum {
    return ProgramChoicesEnumFromJSONTyped(json, false);
}

export function ProgramChoicesEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProgramChoicesEnum {
    return json as ProgramChoicesEnum;
}

export function ProgramChoicesEnumToJSON(value?: ProgramChoicesEnum | null): any {
    return value as any;
}

