export const formatSeconds = (
  seconds: number,
  decimals = 0,
  variant = "long",
): string => {
  /**
   Format a number of seconds into a human-readable time delta.
  */
  const isLong = variant === "long";

  if (seconds < 0) {
    throw Error(`Invalid time: ${seconds} seconds`);
  }
  if (seconds < 1) {
    return decimals > 0 && seconds > 0
      ? `${seconds.toFixed(decimals)} ${isLong ? " seconds" : "s"}`
      : "0";
  }
  let remainder = seconds;

  const years = Math.floor(remainder / 60 / 60 / 24 / 365.25);
  remainder = remainder - years * 24 * 60 * 60 * 365.25;

  const days = Math.floor(remainder / 60 / 60 / 24);
  remainder = remainder - days * 24 * 60 * 60;

  const hours = years === 0 ? Math.floor(remainder / 60 / 60) : 0;
  remainder = remainder - hours * 60 * 60;

  const minutes = years === 0 && days === 0 ? Math.floor(remainder / 60) : 0;
  remainder = remainder - minutes * 60;

  seconds = years === 0 && days === 0 && hours === 0 ? remainder : 0;
  seconds = Math.round(seconds);

  const yearPostfix = isLong ? " year" + (years > 1 ? "s " : " ") : "yr ";
  const dayPostfix = isLong ? " day" + (days > 1 ? "s " : " ") : "d ";
  const hourPostfix = isLong ? " hour" + (hours > 1 ? "s " : " ") : "h ";
  const minutePostfix = isLong ? " minute" + (minutes > 1 ? "s " : " ") : "m ";
  const secondPostfix = isLong ? " second" + (seconds > 1 ? "s " : " ") : "s ";

  return (
    `${years > 0 ? years + yearPostfix : ""}` +
    `${days > 0 ? days + dayPostfix : ""}` +
    `${hours > 0 ? hours + hourPostfix : ""}` +
    `${minutes > 0 ? minutes + minutePostfix : ""}` +
    `${seconds > 0 ? seconds + secondPostfix : ""}`
  );
};

export const formatBytes = (bytes: number, decimals = 1): string => {
  /**
   Format a number of bytes into a human-readable string, e.g., MiB, GiB, etc.
  */
  bytes = Math.round(bytes);
  if (bytes === 0) {
    return "0 Bytes";
  }
  const val = Math.floor(Math.log2(bytes) / Math.log2(1024));
  const units = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];
  return `${parseFloat((bytes / Math.pow(1024, val)).toFixed(decimals))} ${
    units[Math.min(val, units.length - 1)]
  }`;
};

export const calendlyLink =
  "https://calendly.com/d/ck2c-z3p-bfp/getting-started-with-coiled";
