import React from "react";
import { Legend, Tooltip, YAxis } from "recharts";
import { format } from "date-fns";
import { ChartProps, Domain, Variation } from "./types";
import { ZoomableAreaChartWithVariations } from "./ZoomableAreaChartWithVariations";
import { GroupedTooltip } from "./GroupedTooltip";
import { GroupedLegend } from "./GroupedLegend";
import { YAXIS_WIDTH } from "./const";

export const AverageWorkerCpuChart = (
  props: ChartProps,
): React.ReactElement => {
  const domains: Domain[] = [
    { name: "iowait", color: "#2159FF", stack: 0 },
    { name: "irq", color: "#8fe38f", stack: 0 },
    { name: "nice", color: "#eeca3b", stack: 0 },
    { name: "softirq", color: "#dddddd", stack: 0 },
    { name: "steal", color: "#9ecae9", stack: 0 },
    { name: "system", color: "#72b7b2", stack: 0 },
    { name: "user", color: "#cc8899", stack: 0 },
  ];

  const totalDomain: Domain = {
    name: "total",
    color: { stroke: "#908e89", fill: "white", opacity: 0 },
    stack: 1,
  };

  const variations: Variation[] = [
    {
      name: "Worker Average",
      groupKey: "mode",
      queries: [
        {
          name: "cpu_rate | avg_by(mode) | *100",
        },
      ],
      domains,
    },
    {
      name: "Worker Total",
      groupKey: "mode",
      queries: [
        {
          name: "cpu_rate | sum_by(mode) | *100",
        },
        {
          name: "cpu_rate | sum_by(instance,cpu) | count | *100",
          typeLabel: "total",
        },
      ],
      domains: domains.concat([totalDomain]),
      yAxis: <YAxis unit="%" width={YAXIS_WIDTH} />,
    },

    {
      name: "Worker Distribution",
      groupKey: "type",
      queries: [
        {
          name: "cpu_rate | sum_by(instance,cpu) | min | *100",
          typeLabel: "min",
        },
        {
          name: "cpu_rate | sum_by(instance,cpu) | max | *100",
          typeLabel: "max",
        },
        {
          name: "cpu_rate | sum_by(instance,cpu) | pct20 | *100",
          typeLabel: "pct20",
        },
        {
          name: "cpu_rate | sum_by(instance,cpu) | pct80 | *100",
          typeLabel: "pct80",
        },
        {
          name: "cpu_rate | sum_by(instance,cpu) | pct50 | *100",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
    {
      name: "Scheduler",
      groupKey: "mode",
      queries: [
        {
          name: "scheduler:cpu_rate | sum_by(mode) | *100",
        },
        {
          name: "scheduler:cpu_rate | sum_by(cpu) | count | *100",
          typeLabel: "total",
        },
      ],
      domains: domains.concat([totalDomain]),
      yAxis: <YAxis unit="%" width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="CPU"
        variations={variations}
        tooltip={
          <Tooltip
            content={
              <GroupedTooltip
                formatter={(value, name: string) => {
                  let formattedValue: string;
                  if (Array.isArray(value)) {
                    formattedValue = `${Math.round(
                      value[0] as number,
                    )}%–${Math.round(value[1] as number)}%`;
                  } else {
                    formattedValue = `${Math.round(
                      value as unknown as number,
                    )}%`;
                  }
                  return [formattedValue, name];
                }}
                labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
              />
            }
            wrapperStyle={{
              outline: "none",
              zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
          />
        }
        yAxis={
          <YAxis
            dataKey={(value) => Math.round(value * 100)}
            domain={[0, 100]}
            unit="%"
            width={YAXIS_WIDTH}
          />
        }
        {...props}
      />
    </>
  );
};

export const SingleNodeCpuChart = (props: ChartProps): React.ReactElement => {
  const domains: Domain[] = [
    { name: "iowait", color: "#2159FF", stack: 0 },
    { name: "irq", color: "#8fe38f", stack: 0 },
    { name: "nice", color: "#eeca3b", stack: 0 },
    { name: "softirq", color: "#dddddd", stack: 0 },
    { name: "steal", color: "#9ecae9", stack: 0 },
    { name: "system", color: "#72b7b2", stack: 0 },
    { name: "user", color: "#cc8899", stack: 0 },
  ];

  const totalDomain: Domain = {
    name: "total",
    color: { stroke: "#908e89", fill: "white", opacity: 0 },
    stack: 1,
  };

  const variations: Variation[] = [
    {
      name: "Total across cores",
      groupKey: "mode",
      queries: [
        {
          name: "scheduler:cpu_rate | sum_by(mode) | *100",
        },
        {
          name: "scheduler:cpu_rate | sum_by(cpu) | count | *100",
          typeLabel: "total",
        },
      ],
      domains: domains.concat([totalDomain]),
      yAxis: <YAxis unit="%" width={YAXIS_WIDTH} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="Host CPU"
        variations={variations}
        tooltip={
          <Tooltip
            content={
              <GroupedTooltip
                formatter={(value, name: string) => {
                  let formattedValue: string;
                  if (Array.isArray(value)) {
                    formattedValue = `${Math.round(
                      value[0] as number,
                    )}%–${Math.round(value[1] as number)}%`;
                  } else {
                    formattedValue = `${Math.round(
                      value as unknown as number,
                    )}%`;
                  }
                  return [formattedValue, name];
                }}
                labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
              />
            }
            wrapperStyle={{
              outline: "none",
              zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
          />
        }
        yAxis={
          <YAxis
            dataKey={(value) => Math.round(value * 100)}
            domain={[0, 100]}
            unit="%"
            width={YAXIS_WIDTH}
          />
        }
        {...props}
      />
    </>
  );
};

export const CombinedCpuChart = (props: ChartProps): React.ReactElement => {
  const domains: Domain[] = [
    { name: "iowait", color: "#2159FF", stack: 0 },
    { name: "irq", color: "#8fe38f", stack: 0 },
    { name: "nice", color: "#eeca3b", stack: 0 },
    { name: "softirq", color: "#dddddd", stack: 0 },
    { name: "steal", color: "#9ecae9", stack: 0 },
    { name: "system", color: "#72b7b2", stack: 0 },
    { name: "user", color: "#cc8899", stack: 0 },
  ];

  const totalDomain: Domain = {
    name: "total",
    color: { stroke: "#908e89", fill: "white", opacity: 0 },
    stack: 1,
  };

  const variations: Variation[] = [
    {
      name: "Average",
      groupKey: "mode",
      queries: [
        {
          name: "all:cpu_rate | avg_by(mode) | *100",
        },
      ],
      domains,
    },
    {
      name: "Total",
      groupKey: "mode",
      queries: [
        {
          name: "all:cpu_rate | sum_by(mode) | *100",
        },
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | count | *100",
          typeLabel: "total",
        },
      ],
      domains: domains.concat([totalDomain]),
      yAxis: <YAxis unit="%" width={YAXIS_WIDTH} />,
    },

    {
      name: "Distribution",
      groupKey: "type",
      queries: [
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | min | *100",
          typeLabel: "min",
        },
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | max | *100",
          typeLabel: "max",
        },
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | pct20 | *100",
          typeLabel: "pct20",
        },
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | pct80 | *100",
          typeLabel: "pct80",
        },
        {
          name: "all:cpu_rate | sum_by(instance,cpu) | pct50 | *100",
          typeLabel: "median",
        },
      ],
      domains: [
        { name: "min", color: null, stack: 0, range: 1 },
        { name: "max", color: null, stack: 0, range: 1 },
        { name: "pct20", color: null, stack: 0, range: 2 },
        { name: "pct80", color: null, stack: 0, range: 2 },
        {
          name: "median",
          color: { stroke: "#2159FF", fill: "white" },
          stack: 0,
        },
        {
          name: "pct20-pct80",
          color: { stroke: "", fill: "#2159FF", opacity: 0.4 },
        },
        {
          name: "min-max",
          color: { stroke: "", fill: "#2159FF", opacity: 0.2 },
        },
      ],
      legend: <Legend content={GroupedLegend} />,
    },
  ];

  return (
    <>
      <ZoomableAreaChartWithVariations
        title="CPU"
        variations={variations}
        tooltip={
          <Tooltip
            content={
              <GroupedTooltip
                formatter={(value, name: string) => {
                  let formattedValue: string;
                  if (Array.isArray(value)) {
                    formattedValue = `${Math.round(
                      value[0] as number,
                    )}%–${Math.round(value[1] as number)}%`;
                  } else {
                    formattedValue = `${Math.round(
                      value as unknown as number,
                    )}%`;
                  }
                  return [formattedValue, name];
                }}
                labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
              />
            }
            wrapperStyle={{
              outline: "none",
              zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
            }}
            isAnimationActive={false}
          />
        }
        yAxis={
          <YAxis
            dataKey={(value) => Math.round(value * 100)}
            domain={[0, 100]}
            unit="%"
            width={YAXIS_WIDTH}
          />
        }
        {...props}
      />
    </>
  );
};
