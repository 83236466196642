import React, { useMemo } from "react";
import {
  useOrganization,
  useOrganizationMembershipDetails,
} from "../../crud/organizations/hooks";
import { BreadCrumbPart, Page } from "../../shared-components/Page";
import { Urls } from "../../domain/urls";
import { OrganizationMemberForm } from "./OrganizationMemberForm";

export const OrganizationEditMemberform = ({
  organizationId,
  membershipId,
  type,
}: {
  organizationId: number;
  membershipId: number;
  type: "Invite" | "Membership";
}): React.ReactElement => {
  const { data: org } = useOrganization(organizationId);
  const { data: organizationMembership } =
    useOrganizationMembershipDetails(membershipId);
  const breadCrumbs = useMemo<BreadCrumbPart[]>(() => {
    return [
      {
        text: "Organization",
        link: `/${Urls.Team}`,
      },
      {
        text: org?.name || "",
      },
      {
        text: organizationMembership?.user.username || "",
        link: `/team/${organizationId}/edit-member/${membershipId}`,
      },
    ];
  }, [org, organizationMembership, organizationId, membershipId]);
  return (
    <Page title="Edit Organization Member" breadCrumbs={breadCrumbs}>
      <OrganizationMemberForm
        organizationId={organizationId}
        membershipId={membershipId}
        type={type}
      />
    </Page>
  );
};
