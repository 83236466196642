import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  StepProps,
  StepperContext,
} from "../../../shared-components/StepperContext";
import { BackendTypeServer } from "../../../domain/people";
import { useForm } from "react-hook-form";
import { AWSIcon } from "../../../icons/AWSIcon";
import { GCPIcon } from "../../../icons/GCPIcon";
import { WorkspaceSchema } from "../../../api-client";
import { AzureIcon } from "../../../icons/AzureIcon";

export type BackendFormValues = {
  backend?: BackendTypeServer;
};

type SelectBackendFormProps = StepProps & {
  workspace: WorkspaceSchema;
  defaultBackend: BackendTypeServer;
  onBackendSelected: (backend: BackendTypeServer) => void;
};

export const SelectBackendForm = ({
  index,
  workspace,
  defaultBackend,
  onBackendSelected,
}: SelectBackendFormProps): React.ReactElement => {
  const { dispatch } = useContext(StepperContext);

  const { watch, register, setValue, reset } = useForm<BackendFormValues>({
    defaultValues: {
      backend: defaultBackend,
    },
  });
  useEffect(() => {
    if (workspace.activeBackend) {
      dispatch({ type: "STEP_COMPLETED", payload: index });
    } else {
      reset({ backend: undefined });
    }
  }, [workspace.activeBackend, dispatch, index, reset]);
  const {} = register("backend");

  return (
    <Stack spacing={2} alignItems="flex-start">
      <ToggleButtonGroup
        exclusive
        color="secondary"
        onChange={(e, v) => {
          if (v === null) return;
          onBackendSelected(v);
          dispatch({ type: "NEXT_STEP", payload: index });
          dispatch({ type: "STEP_COMPLETED", payload: index });
          dispatch({ type: "COLLAPSE_STEP", payload: index });
          setValue("backend", v);
        }}
        value={watch("backend")}
      >
        <ToggleButton value={BackendTypeServer.AWS_HOSTED}>
          <Stack alignItems="center">
            <AWSIcon sx={{ width: "70px", height: "70px " }} />
            <Typography>Amazon Web Services</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton size="large" value={BackendTypeServer.AZURE_HOSTED}>
          <Stack alignItems="center">
            <AzureIcon sx={{ width: "70px", height: "70px " }} />
            <Typography>Microsoft Azure</Typography>
          </Stack>
        </ToggleButton>
        <ToggleButton size="large" value={BackendTypeServer.GCP_HOSTED}>
          <Stack alignItems="center">
            <GCPIcon sx={{ width: "70px", height: "70px " }} />
            <Typography>Google Cloud Platform</Typography>
          </Stack>
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
};
