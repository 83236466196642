import { format } from "date-fns";
import React from "react";
import {
  DotProps,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { ComputationSummary } from "../../Analytics/ClusterStatistics";
import { theme } from "../../../../theme";
import { darken, lighten } from "@mui/system";
import { Tooltip, Box } from "@mui/material";
import { formatBytes, formatSeconds } from "../../../../utils";
import { TimeRange } from "../../types";
import { YAXIS_WIDTH } from "./const";

type ComputationTimelineProps = {
  computations: ComputationSummary[];
  timeRange?: TimeRange;
  focusedComputation: string | undefined;
  hoveredComputation: string | undefined;
  doComputationClick: (c?: ComputationSummary) => void;
  doComputationHover: (c?: ComputationSummary) => void;
};

export const ComputationTimeline = (
  props: ComputationTimelineProps,
): React.ReactElement => {
  const levels = props.computations.map((value) => value.level);
  const maxLevel = Math.max(...levels);
  const containerHeight = 60 + 25 * maxLevel;

  return (
    <ResponsiveContainer width="100%" height={containerHeight}>
      <LineChart
        layout="vertical"
        data={convertTasksToRechartsData(props.computations, props.timeRange)}
        style={{ padding: 0 }}
        margin={{
          top: 0,
          right: 0,
          left: YAXIS_WIDTH,
          bottom: 0,
        }}
      >
        <XAxis
          minTickGap={50}
          tickCount={10}
          type="number"
          scale="time"
          domain={props.timeRange}
          tickFormatter={(v, i) => format(new Date(v), "HH:mm:ss")}
          allowDuplicatedCategory
        />

        <YAxis width={0} dataKey="name" type="category" />
        <Legend />
        <Line
          dataKey="start"
          strokeWidth={0} // just show "dots" (computation boxes), not line
          dot={GetCustomizedDot(props)}
          activeDot={false}
          legendType="none"
          isAnimationActive={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

type ComputationPayloadProps = {
  computation: ComputationSummary;
  start: number;
  duration: number;
  timeRange: TimeRange;
};

const convertTasksToRechartsData = (
  computations: ComputationSummary[],
  timeRange?: TimeRange,
) => {
  if (computations.length === 0) return [];
  if (!timeRange) return [];

  return (
    computations
      // only show tasks with start and stop time set
      .filter((computation) => computation.start && computation.stop)
      .map((computation) => {
        const start = new Date(computation.start! * 1000).getTime();
        // add 5000 ms to adjust for skew between analytics and Prometheus metrics
        const duration =
          new Date(computation.stop! * 1000).getTime() - start + 5000;
        const payload: ComputationPayloadProps = {
          computation,
          start,
          duration,
          timeRange,
        };

        return payload;
      })
  );
};

type CustomizedDotProps = DotProps & { payload: ComputationPayloadProps };

const handleOnMouseUp = (
  clickedComputation: ComputationSummary | undefined,
  focusedComputationIdentifier: string | undefined,
  setFocusedComputation: (
    clickedComputation: ComputationSummary | undefined,
  ) => void,
) => {
  if (focusedComputationIdentifier === clickedComputation?.identifier) {
    setFocusedComputation(undefined);
  } else {
    setFocusedComputation(clickedComputation);
  }
};

const GetCustomizedDot = ({
  focusedComputation,
  hoveredComputation,
  doComputationClick,
  doComputationHover,
}: ComputationTimelineProps) => {
  return (props: CustomizedDotProps) => {
    const {
      cx,
      payload: { timeRange, duration, computation },
    } = props;
    if (!props.width) return <></>;

    const chartDuration = ((timeRange[1] as number) - timeRange[0]) as number;
    const chartWidth = props.width as number;
    const normalizedWidth = (duration * chartWidth) / chartDuration;
    const isFocused = focusedComputation === computation.identifier;
    const isHovered = hoveredComputation === computation.identifier;

    const hasExceptions = computation.exceptions.length > 0;

    const baseFillColor = hasExceptions
      ? theme.palette.custom.red.pale
      : theme.palette.custom.blue.pale;

    const baseStrokeColor = hasExceptions
      ? lighten(theme.palette.custom.red.main, 0.8)
      : darken(theme.palette.custom.blue.pale, 0.08);

    const fillColor = isFocused
      ? theme.palette.secondary.main
      : isHovered
        ? darken(baseFillColor, 0.04)
        : baseFillColor;

    const strokeColor = isFocused
      ? theme.palette.secondary.main
      : baseStrokeColor;

    const durationText =
      computation.start &&
      computation.stop &&
      computation.start < computation.stop
        ? formatSeconds(
            new Date(computation.stop).getTime() -
              new Date(computation.start).getTime(),
            2,
          )
        : "0";

    return (
      <Tooltip
        key={computation.identifier}
        title={
          <>
            {computation.label ? <div>{computation.label}</div> : <></>}
            <div>
              {computation.nTasks ? computation.nTasks.toLocaleString() : 0}{" "}
              tasks processed {formatBytes(computation.nbytes)}
              {durationText !== "0" && ` in ${durationText}`}
            </div>
            {computation.exceptions.length > 0 && (
              <div>
                <br />
                <b>
                  {computation.exceptions.length} exception
                  {computation.exceptions.length > 1 && `s`}
                </b>
                :
                {computation.exceptions.map((e) => (
                  <div key={e.exception}>
                    <Box
                      sx={{
                        fontFamily: "monospace, monospace",
                        display: "inline",
                      }}
                    >
                      {e.exception}
                    </Box>{" "}
                    ({e.count} time{e.count > 1 && `s`})
                  </div>
                ))}
              </div>
            )}
          </>
        }
        placement="bottom-start"
      >
        <svg
          key={computation.identifier}
          onMouseEnter={() => doComputationHover(computation)}
          onMouseLeave={() => doComputationHover(undefined)}
          onMouseUp={() =>
            handleOnMouseUp(computation, focusedComputation, doComputationClick)
          }
          style={{
            cursor: "pointer",
            userSelect: "none",
            WebkitUserSelect: "none",
          }}
        >
          <rect
            key={computation.identifier}
            width={normalizedWidth}
            height={computation.level > 0 ? 10 : 20}
            x={cx}
            y={computation.level > 0 ? 30 + 10 * (computation.level - 1) : 0}
            fill={fillColor}
            stroke={strokeColor}
          />
        </svg>
      </Tooltip>
    );
  };
};
