import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Alert,
  Box,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  IconButtonProps,
  SvgIcon,
  Typography,
  Tooltip as MuiTooltip,
  Stack,
  useTheme,
} from "@mui/material";
import {
  Area,
  AreaChart,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { format } from "date-fns";
import prettyBytes from "pretty-bytes";
import isObject from "lodash/isObject";
import { SamplingMethod, sampleMetrics } from "../sampleMetrics";
import { GroupedMetric } from "../../../../crud/metrics/types";
import HeightIcon from "@mui/icons-material/Height";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { InboxTwoTone } from "@mui/icons-material";
import { TimeRange } from "../../types";
import { GroupedTooltip } from "./GroupedTooltip";
import { Domain } from "./types";
import { YAXIS_WIDTH } from "./const";

export type ZoomableAreaChartProps = {
  title?: string | ReactElement;
  data?: GroupedMetric[];
  maxValue?: number;
  isLoading: boolean;
  isError: boolean;
  samplingMethod?: SamplingMethod;
  domains: Domain[];
  yAxis?: ReactElement;
  xAxis?: ReactElement;
  tooltip?: ReactElement;
  legend?: ReactElement;
  cartesianGrid?: ReactElement;
  Chart?: any;
  Cartesian?: any;
  height?: number;
  timeRange?: TimeRange;
  setTimeRange: (timeRange?: TimeRange) => void;
  defaultTimeRange?: TimeRange;
  refAreaLeft: number;
  refAreaRight: number;
  setRefAreaLeft: (refAreaLeft: number) => void;
  setRefAreaRight: (refAreaRight: number) => void;
  clusterIsRunning?: boolean;
  emptyDataMessage?: string | ReactNode;
};

/**
 * A zoomable area chart that uses an array of type GroupedMetric[] as its data source.
 */
/**
 * A zoomable area chart that uses an array of type GroupedMetric[] as its data source.
 */
export const ZoomableAreaChart = ({
  title,
  data,
  isLoading,
  isError,
  samplingMethod,
  domains,
  yAxis,
  xAxis,
  tooltip,
  legend,
  cartesianGrid,
  Chart,
  Cartesian,
  height,
  timeRange,
  setTimeRange,
  defaultTimeRange,
  refAreaLeft,
  refAreaRight,
  setRefAreaLeft,
  setRefAreaRight,
  emptyDataMessage,
}: ZoomableAreaChartProps): ReactElement => {
  const theme = useTheme();
  const boxRef = React.useRef<HTMLDivElement>();

  const zoom = useCallback(() => {
    let _refAreaLeft = refAreaLeft;
    let _refAreaRight = refAreaRight;

    setRefAreaLeft(0);
    setRefAreaRight(0);

    if (!_refAreaLeft || !_refAreaRight) return;

    // Don't allow zooming into single datapoint
    if (_refAreaLeft === _refAreaRight) return;

    // Swap left and right if zooming right to left
    if (_refAreaLeft > _refAreaRight)
      [_refAreaLeft, _refAreaRight] = [_refAreaRight, _refAreaLeft];

    setTimeRange([_refAreaLeft, _refAreaRight]);
  }, [
    setTimeRange,
    setRefAreaLeft,
    setRefAreaRight,
    refAreaLeft,
    refAreaRight,
  ]);

  const getXAxis = () =>
    xAxis ? (
      xAxis
    ) : (
      <XAxis
        minTickGap={50}
        dataKey={(obj) => (obj ? obj.timestamp * 1000 : null)}
        type="number"
        scale="time"
        domain={timeRange ? timeRange : ["dataMin", "dataMax"]}
        tickFormatter={(v, i) => format(new Date(v), "HH:mm:ss")}
        allowDuplicatedCategory={false}
        allowDataOverflow
      />
    );

  const getTooltip = () =>
    tooltip ? (
      tooltip
    ) : (
      <Tooltip
        content={
          <GroupedTooltip
            formatter={(value, name: string) => {
              const formattedVal = Array.isArray(value)
                ? value
                    .map((v) => prettyBytes(parseInt(v as string, 10)))
                    .join("–")
                : prettyBytes(parseInt(value as string, 10));
              const formattedName = name.replace("-", "–");
              return [formattedVal, formattedName];
            }}
            labelFormatter={(v) => format(new Date(v), "LLL do, HH:mm:ss")}
          />
        }
        wrapperStyle={{
          outline: "none",
          zIndex: 3, // Making sure the task prefix tooltip doesn't cover the other tooltips
        }}
        isAnimationActive={false}
      />
    );

  const getLegend = () =>
    legend ? (
      legend
    ) : (
      <Legend
        iconType="circle"
        iconSize={11}
        formatter={(value) => <span style={{ color: "#908e89" }}>{value}</span>}
      />
    );

  const getCartesianGrid = () =>
    cartesianGrid ? cartesianGrid : <CartesianGrid stroke="#eee" />;

  const ChartComponent = Chart || AreaChart;
  const CartesianComponent = Cartesian || Area;

  const hasSingleMetric = data?.length === 1;

  const isBarChart = ChartComponent === BarChart;

  const maxSamples = 500; // Max number of samples to show on the chart
  const { data: sampledData, maxValue } = useMemo(
    () =>
      sampleMetrics(data, timeRange, maxSamples, samplingMethod, isBarChart),
    [data, timeRange, samplingMethod, isBarChart],
  );

  const getYAxis = () =>
    yAxis ? (
      yAxis
    ) : (
      <YAxis
        tickFormatter={(value: number) =>
          Number.isFinite(value) ? prettyBytes(value) : "0 B"
        }
        domain={[0, maxValue]}
        width={YAXIS_WIDTH}
      />
    );

  // Track mouseup outside of the chart so we can drag and end selection outside chart
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      // (#1570) Don't trigger the drag-outside-of-metrics-chart when clicking on the computation listing
      const computationListingEl = document.getElementById(
        "computation-listing",
      );
      if (computationListingEl?.contains(e.target as Node)) return;

      e.stopImmediatePropagation();
      zoom();
    };

    document.addEventListener("mouseup", listener);

    return () => {
      document.removeEventListener("mouseup", listener);
    };
  }, [refAreaLeft, refAreaRight, zoom]);

  const maybeSnapToEdge = useCallback(() => {
    const rect = boxRef.current?.getBoundingClientRect();

    const listener = (e: MouseEvent) => {
      e.stopImmediatePropagation();

      if (!rect || !timeRange) return;

      const mouseExitedToRight = e.clientX > rect.left + rect.width;
      const mouseExitedToLeft = e.clientX < rect.left + YAXIS_WIDTH;

      if (mouseExitedToRight) {
        setRefAreaRight(timeRange[1]);
      } else if (mouseExitedToLeft) {
        setRefAreaRight(timeRange[0]);
      }

      document.removeEventListener("mousemove", listener);
    };

    document.addEventListener("mousemove", listener);
  }, [timeRange, setRefAreaRight]);

  // Snap to edges when mouse leaves the chart to the sides
  const onMouseLeave = useCallback(() => {
    maybeSnapToEdge();
  }, [maybeSnapToEdge]);

  const onMouseDown = useCallback(
    (e: { activeLabel: number }) => {
      setRefAreaLeft(e?.activeLabel);
    },
    [setRefAreaLeft],
  );

  const onMouseMove = useCallback(
    (e: any) => {
      // We also want to snap to edge when hovering over yaxis
      if (!e.chartX) {
        maybeSnapToEdge();
      }

      if (refAreaLeft && e?.activeLabel) {
        setRefAreaRight(e?.activeLabel);
      }
    },
    [maybeSnapToEdge, refAreaLeft, setRefAreaRight],
  );

  const hasZoomed = timeRange !== defaultTimeRange;

  return (
    <Box
      sx={{
        "& .chart-button-row": {
          visibility: "hidden",
        },
        "&:hover .chart-button-row": {
          visibility: "visible",
        },
      }}
    >
      {title && (
        <CardHeader
          title={
            <ChartTitle
              title={title}
              timeRange={timeRange}
              setTimeRange={setTimeRange}
              isMaxZoom={sampledData?.length < 2}
            />
          }
          sx={{ pl: 0, pt: 0 }}
        />
      )}
      <Box
        ref={boxRef}
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          // Disable user selection on the chart to fix a bug where
          // Safari will select the text of the axis labels
          userSelect: "none",
          height: `${height || 200}px`,
          position: "relative",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : isError ? (
          <Alert
            sx={{ mb: 1 }}
            severity="error"
          >{`Error fetching ${title} data.`}</Alert>
        ) : sampledData?.length ? (
          <ResponsiveContainer width="100%" height={height || 200}>
            <ChartComponent
              data={sampledData || []}
              syncId="syncEverythingWithThisId"
              syncMethod="value"
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              barCategoryGap={0}
              onMouseLeave={onMouseLeave}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              style={{ cursor: "ew-resize", userSelect: "none" }}
              {...(hasSingleMetric && isBarChart && { barSize: 20 })}
            >
              {getLegend()}
              {getCartesianGrid()}
              {getTooltip()}
              {getYAxis()}
              {getXAxis()}
              {domains
                .filter(({ color }) => !!color)
                .map(({ name, color, stack }) => (
                  <CartesianComponent
                    key={name}
                    type="monotone"
                    isAnimationActive={false}
                    dataKey={name}
                    stackId={stack}
                    stroke={isObject(color) ? color.stroke : color}
                    fill={isObject(color) ? color.fill : color}
                    fillOpacity={
                      isObject(color) && color.opacity != null
                        ? color.opacity
                        : 0.5
                    }
                  />
                ))}
              {refAreaLeft && refAreaRight && (
                <ReferenceArea
                  x1={refAreaLeft}
                  x2={refAreaRight}
                  strokeOpacity={0.3}
                />
              )}
            </ChartComponent>
          </ResponsiveContainer>
        ) : (
          <Stack
            justifyContent={"center"}
            width="100%"
            height="100%"
            sx={{ backgroundColor: theme.palette.grey[100] }}
          >
            {hasZoomed ? null : (
              <Stack
                alignItems={"center"}
                justifyContent={"center"}
                sx={{ color: theme.palette.text.primary, opacity: 0.5 }}
              >
                <InboxTwoTone />
                {emptyDataMessage ? emptyDataMessage : "No Data"}
              </Stack>
            )}
          </Stack>
        )}
      </Box>
    </Box>
  );
};

const ChartTitle = ({
  title,
  timeRange,
  setTimeRange,
  isMaxZoom,
}: {
  title: string | ReactElement;
  timeRange?: TimeRange;
  setTimeRange: (timeRange?: TimeRange) => void;
  isMaxZoom: boolean;
}): React.ReactElement => {
  const theme = useTheme();
  const zoomIn = useCallback(() => {
    if (!timeRange) return;
    const start = timeRange[0] + (timeRange[1] - timeRange[0]) / 4;
    const end = timeRange[1] - (timeRange[1] - timeRange[0]) / 4;
    setTimeRange([start, end]);
  }, [timeRange, setTimeRange]);

  const zoomOut = useCallback(() => {
    if (!timeRange) return;
    const start = timeRange[0] - (timeRange[1] - timeRange[0]) / 2;
    const end = timeRange[1] + (timeRange[1] - timeRange[0]) / 2;
    setTimeRange([start, end]);
  }, [timeRange, setTimeRange]);

  const resetZoom = useCallback(() => {
    setTimeRange(undefined);
  }, [setTimeRange]);

  return (
    <Grid container>
      <Grid item xs>
        {title}
      </Grid>
      <Grid item xs={"auto"}>
        {true ? (
          <Grid container spacing={2} className="chart-button-row">
            <Grid item>
              <Typography
                variant="body2"
                sx={{
                  fontSize: theme.typography.pxToRem(14),
                  textTransform: "none",
                  color: theme.palette.custom.grey.dark,
                  lineHeight: 2,
                }}
              >
                <HeightIcon
                  sx={{
                    verticalAlign: "middle",
                    transform: "rotate(90deg)",
                  }}
                />{" "}
                Drag to zoom
              </Typography>
            </Grid>
            <Grid item>
              <TopRightButton
                onClick={zoomIn}
                Icon={ZoomInIcon}
                disabled={isMaxZoom}
              />
              <TopRightButton onClick={zoomOut} Icon={ZoomOutIcon} />
              <MuiTooltip title={<>Reset Zoom</>}>
                <TopRightButton onClick={resetZoom} Icon={ZoomOutMapIcon} />
              </MuiTooltip>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

type TopRightButtonType = {
  Icon: typeof SvgIcon;
} & IconButtonProps;

type TopRightButtonRefType =
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null;

const TopRightButton = React.forwardRef(
  (props: TopRightButtonType, ref?: TopRightButtonRefType) => {
    const { Icon, ...rest } = props;
    return (
      <IconButton size="small" sx={{ minWidth: "auto" }} {...rest} ref={ref}>
        <Icon fontSize="small" />
      </IconButton>
    );
  },
);
