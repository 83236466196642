/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBillingEventSchema
 */
export interface CreateBillingEventSchema {
    /**
     * 
     * @type {number}
     * @memberof CreateBillingEventSchema
     */
    programId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateBillingEventSchema
     */
    note: string;
    /**
     * 
     * @type {number}
     * @memberof CreateBillingEventSchema
     */
    amountCredits: number;
}

/**
 * Check if a given object implements the CreateBillingEventSchema interface.
 */
export function instanceOfCreateBillingEventSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "programId" in value;
    isInstance = isInstance && "note" in value;
    isInstance = isInstance && "amountCredits" in value;

    return isInstance;
}

export function CreateBillingEventSchemaFromJSON(json: any): CreateBillingEventSchema {
    return CreateBillingEventSchemaFromJSONTyped(json, false);
}

export function CreateBillingEventSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBillingEventSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'programId': json['program_id'],
        'note': json['note'],
        'amountCredits': json['amount_credits'],
    };
}

export function CreateBillingEventSchemaToJSON(value?: CreateBillingEventSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'program_id': value.programId,
        'note': value.note,
        'amount_credits': value.amountCredits,
    };
}

