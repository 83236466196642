/* tslint:disable */
/* eslint-disable */
/**
 * NinjaAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateProgramSchema
 */
export interface UpdateProgramSchema {
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchema
     */
    spendLimit: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProgramSchema
     */
    spendLimitShouldStopInstances: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProgramSchema
     */
    organizationCreditLimit: number;
}

/**
 * Check if a given object implements the UpdateProgramSchema interface.
 */
export function instanceOfUpdateProgramSchema(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "spendLimit" in value;
    isInstance = isInstance && "spendLimitShouldStopInstances" in value;
    isInstance = isInstance && "organizationCreditLimit" in value;

    return isInstance;
}

export function UpdateProgramSchemaFromJSON(json: any): UpdateProgramSchema {
    return UpdateProgramSchemaFromJSONTyped(json, false);
}

export function UpdateProgramSchemaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProgramSchema {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'spendLimit': json['spend_limit'],
        'spendLimitShouldStopInstances': json['spend_limit_should_stop_instances'],
        'organizationCreditLimit': json['organization_credit_limit'],
    };
}

export function UpdateProgramSchemaToJSON(value?: UpdateProgramSchema | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'spend_limit': value.spendLimit,
        'spend_limit_should_stop_instances': value.spendLimitShouldStopInstances,
        'organization_credit_limit': value.organizationCreditLimit,
    };
}

